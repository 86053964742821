import {
  translate
} from 'components/TranslationProvider'

class ResultsCount extends React.Component {
  counter(count, query) {
    const t = this.props.t
    if (count != 0) {
      return (
        <div>
          { t('search_results_page:views.result', {count: count}) } <b> "{query}" </b>
        </div>
      )
    }
    if (query == null || query == '') {
      return (
        <div>
          { t('search_results_page:views.empty_prompt') }
        </div>
      )
    } else {
      return (
        <div>
          { t('search_results_page:views.no_results_1') } <b> "{query}" </b> { t('search_results_page:views.no_results_2') }
        </div>
      )
    }
  }

  render() {
    return (
      <div className="result-number">{this.counter(this.props.count, this.props.query)}</div>
    )
  }
}
export default translate(['ResultsCount'])(ResultsCount)
