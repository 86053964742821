import CareerPasswordRequest from './CareerPasswordRequest'
import React from 'react'
import { hasOnePermissionOf } from '../../shared'
import { CareerButton } from './CareerButton'
import { useTranslation } from 'react-i18next'

const EditArea = () => {
  const [t] = useTranslation()
  const { intranet_request } = window.DREAMin
  if (!scrivito.in_editable_view() && !intranet_request) return null

  const careEditor = hasOnePermissionOf([
    'care_career_offer_admin',
    'care_career_offer_editor',
    'super',
    'karriere',
  ])
  const careerEditor = hasOnePermissionOf([
    'career_offer_admin',
    'career_offer_editor',
    'super',
    'karriere',
  ])

  const createCareerOfferPage = (language, offerType) => {
    scrivito
      .create_obj({
        job_title: 'Neue Stellenanzeige',
        _obj_class: 'CareerOfferPage',
        language_iso: language,
        offer_type: offerType,
        extended_upload: offerType == 'care' ? 'yes' : 'no',
        publish_ba: offerType == 'care' ? 'no' : 'yes',
      })
      .then(function (data) {
        window.location.href = '/' + data.id
      })
  }

  return (
    <React.Fragment>
      {scrivito.in_editable_view() && (
        <div className="col-md-12">
          <CareerButton
            disabled={!careEditor}
            onClick={() => createCareerOfferPage('en', 'care')}
            label={t('career_overview_page:views.job_offer_en_care')}
          />
          <CareerButton
            disabled={!careEditor}
            onClick={() => createCareerOfferPage('de', 'care')}
            label={t('career_overview_page:views.job_offer_de_care')}
          />
          <CareerButton
            disabled={!careerEditor}
            onClick={() => createCareerOfferPage('en', 'default')}
            label={t('career_overview_page:views.job_offer_en')}
          />
          <CareerButton
            disabled={!careerEditor}
            onClick={() => createCareerOfferPage('de', 'default')}
            label={t('career_overview_page:views.job_offer_de')}
          />
        </div>
      )}
      {(intranet_request || scrivito.in_editable_view()) && (
        <CareerPasswordRequest />
      )}
    </React.Fragment>
  )
}
export default EditArea
