import PropTypes from 'prop-types'
import parse from 'html-react-parser'

const KumpassHitListEntry = ({
  path,
  id,
  synonyms,
  description,
  color,
  class_name,
  title,
}) => {
  return (
    <li className="col-md-6 kumpass-index">
      <a href={path} key={id}>
        <div className={color + '_border section-icon col-md-1'}></div>
        <div className={'col-md-11'}>
          <div className={'category_markup'}>
            {parse(title)}
            <div className="synonym-box synonym-label">
              {synonyms.join(' | ')}
            </div>
          </div>
          <div className="desc_excerpt">{parse(description)}</div>
          <div className={'category ' + color}>{class_name}</div>
        </div>
      </a>
    </li>
  )
}

KumpassHitListEntry.defaultProps = {
  synonyms: [],
}

KumpassHitListEntry.propTypes = {
  class_name: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.any,
  path: PropTypes.string,
  synonyms: PropTypes.array,
  title: PropTypes.string,
}

export default KumpassHitListEntry
