import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ArchiveDetail from './ArchiveDetail'
import ReviewStatus from './news_overview_page/ReviewStatus'
import NewsBox from './NewsBox'
import PageDetailPreview from './PageDetailPreview'
import TeaserSnippet from './TeaserSnippet'
import ParamContext from './overview_pages/ParamContext'

const PageList = ({ results }) => {
  // API returns results in results.results
  const { archived, query } = useContext(ParamContext)

  if (!results || results.total_results_count === 0) return 'No events found'

  if (results.results) results = results.results

  const mainClassName = archived ? 'archiv-list' : 'news-list'

  const generateEntry = (item, index) => {
    const { image, review_status, published_on, category } = item

    const info_line = [published_on, category.join(', ')]
      .filter(Boolean)
      .join(' | ')

    return (
      <NewsBox image={image} big_image={index === 0} key={index.toString()}>
        <PageDetailPreview
          {...item}
          info_line={info_line}
          big_image={index === 0}
          query={query}
          key="pp"
        />

        {archived ? (
          <ArchiveDetail {...item} />
        ) : (
          <TeaserSnippet {...item} query={query} key="teaser">
            {review_status && <ReviewStatus status={review_status} />}
          </TeaserSnippet>
        )}
      </NewsBox>
    )
  }

  return (
    <div className={`row ${mainClassName}`}>
      <div className="col-sm-12">
        {results && Array.isArray(results) ? results.map(generateEntry) : ''}
      </div>
    </div>
  )
}

PageList.propTypes = {
  archived: PropTypes.bool,
  items: PropTypes.array,
  listComponent: PropTypes.func,
  query: PropTypes.string,
}
export default PageList
