import ScrollableAnchor from 'react-scrollable-anchor'
import ScrollUpButton from 'react-scroll-up-button'
import KumpassHitListEntry from './KumpassHitListEntry'

export const KumpassHitList = ({ letter, hits }) => {
  return (
    <div className="row">
      <ScrollableAnchor id={letter}>
        <div className="letter" key={letter} id={letter}>
          {letter}
        </div>
      </ScrollableAnchor>

      <ul>
        {hits.map(element => (
          <KumpassHitListEntry {...element} key={element.id} />
        ))}
      </ul>
      <ScrollUpButton />
    </div>
  )
}

export default KumpassHitList
