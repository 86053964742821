import SearchForm from 'components/overview_pages/SearchForm'
import ResultsCount from 'components/search_page/ResultsCount'
import SearchCluster from 'components/search_page/SearchCluster'
import { scrollToTop } from '../shared'
class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hit_clusters: [],
      query: props.query_params.q || '',
      searching: false,
      count: 0,
      cluster_urls: {},
      show_legacy_categories: this.props.show_legacy_categories,
    }
    ;(this.childRef = React.createRef()),
      (this.getResults = _.throttle(this.getResults, 500).bind(this))
    this.renderSearchCluster = this.renderSearchCluster.bind(this)
  }

  componentDidMount() {
    this.getResults(this.state.query)
  }

  setSearching = () => {
    this.setState({ searching: true })
  }

  setQueryResults = (response, query) => {
    this.setState({
      searching: false,
      hit_clusters: response.hit_clusters,
      query: query,
      count: response.count,
      cluster_urls: response.cluster_urls,
    })
    this.renderContentArea()
  }

  getResults(query, page) {
    let queryValue = `${query}`
    if (queryValue.length < 2) return

    const { search_path } = this.props

    this.setState({
      searching: true,
      query: queryValue,
      hit_clusters: [],
    })
    scrollToTop(this.childRef)

    $.get({
      url: search_path,
      dataType: 'json',
      data: {
        q: queryValue,
        page: page,
      },
      success: data => this.setQueryResults(data, queryValue),
      error: data => this.setState({ searching: false, hit_clusters: [] }),
    })
  }

  renderSearchCluster(clusters, name, size) {
    let current_cluster = clusters.find(x => x.cluster === name)
    if (current_cluster != undefined) {
      if (name == 'all') {
        return (
          <div className={clusters.length == 1 ? 'col-md-12' : 'col-md-8'}>
            <SearchCluster
              key={name}
              show_legacy_categories={this.state.show_legacy_categories}
              cluster_url={this.state.cluster_urls[name]}
              selected_cluster={current_cluster}
              filter={name}
              size={size}
              clusters={clusters}
              query={this.state.query}
              goToPage={() => this.goToPage()}
            />
          </div>
        )
      } else {
        return (
          <div
            className={
              clusters.find(x => x.cluster === 'all') != undefined
                ? 'col-md-4'
                : 'col-md-12'
            }>
            <SearchCluster
              key={name}
              show_legacy_categories={this.state.show_legacy_categories}
              cluster_url={this.state.cluster_urls[name]}
              selected_cluster={current_cluster}
              filter={name}
              size={size}
              clusters={clusters}
              query={this.state.query}
              goToPage={() => this.goToPage()}
            />
          </div>
        )
      }
    }
  }

  renderContentArea() {
    const { state } = this
    let clusters = state.hit_clusters

    if (state.searching && !state.hit_clusters.length) {
      return (
        <div className="text-center">
          <i className="fas fa-2x fa-spinner fa-spin"></i>
        </div>
      )
    }
    return (
      <React.Fragment>
        <ResultsCount count={state.count} query={state.query} />
        <div className="row search-result-area">
          {this.renderSearchCluster(clusters, 'all', 10)}
          {this.renderSearchCluster(clusters, 'kumpass', 3)}
          {this.renderSearchCluster(clusters, 'news', 3)}
          {this.renderSearchCluster(clusters, 'events', 3)}
          {this.renderSearchCluster(clusters, 'career', 3)}
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        <div className="searchPage">
          <SearchForm
            ref={this.childRef}
            setSearching={() => this.setSearching()}
            performSearch={this.getResults}
            defaultValue={this.state.query}
          />
          {this.renderContentArea()}
        </div>
      </div>
    )
  }
}

export default SearchPage
