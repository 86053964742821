import React, { useState } from 'react'
import { apiFetch, fetchPost } from '../../shared'
import ApiResponse from './ApiResponse'
import ObjDetailsTable from './ObjDetailsTable'
import { PulseLoader } from 'react-spinners'

export const AdminTreeActions = () => {
  const [id, setId] = useState('')
  const [details, setDetails] = useState(null)
  const [response, setResponse] = useState(null)
  const [action, setAction] = useState(null)
  const [progress, setProgress] = useState(false)

  const onChange = e => {
    setId(e.currentTarget.value)
  }

  const searchObj = () => {
    setProgress(true)
    setResponse(null)
    fetch(`/admin/tree/${id}.json`)
      .then(response => {
        if (response.status == 200) {
          response.json().then(res => {
            setDetails(res)
          })
        } else {
          onApiResponse(response)
        }
        setProgress(false)
      })
      .catch(onApiResponse)
  }

  const execAction = command => {
    switch (command) {
      case 'copy':
        setProgress(true)
        fetchPost(`/admin/tree.json`, { id })
          .then(onApiResponse)
          .catch(onApiResponse)
        break
      case 'delete':
        let confi = window.confirm(
          I18n.translate('admin_dashboard_page.tree_actions.delete.confirm')
        )
        if (confi) {
          setProgress(true)
          apiFetch(`/admin/tree/${id}.json`, null, 'delete')
            .then(onApiResponse)
            .catch(onApiResponse)
        }
        break
    }
  }

  const onApiResponse = res => {
    setProgress(false)
    setResponse(res)
    setDetails(null)
  }

  const buttonClick = type => {
    setAction(type)
    execAction(type)
  }

  if (!window.scrivito.in_editable_view()) {
    return (
      <div>
        {I18n.translate(
          'admin_dashboard_page.tree_actions.switch_to_editable_view'
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <h4>Teilbaum bearbeiten</h4>
        </div>
        <div className="col-sm-6">
          <p>
            {I18n.translate('admin_dashboard_page.tree_actions.search.title')}
            <br />
            <span className="small">
              {I18n.translate('admin_dashboard_page.tree_actions.search.desc')}
            </span>
          </p>
        </div>
        <div className="col-sm-6">
          <input onChange={onChange} />
          <button onClick={searchObj}>
            {I18n.translate('admin_dashboard_page.tree_actions.search.find')}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 form-group">
          {details && (
            <React.Fragment>
              <ObjDetailsTable {...details} />
              {/* <input
                className="btn btn-primary"
                type="button"
                value={I18n.translate('admin_dashboard_page.tree_actions.copy.action')}
                onClick={() => buttonClick('copy')}
              /> */}
              <input
                disabled={progress}
                className="btn btn-danger"
                type="button"
                value={I18n.translate(
                  'admin_dashboard_page.tree_actions.delete.action'
                )}
                onClick={() => buttonClick('delete')}
              />
            </React.Fragment>
          )}

          <PulseLoader size={5} color="#666666" loading={progress} />

          <ApiResponse
            response={response}
            okMessage={I18n.translate(
              `admin_dashboard_page.tree_actions.${action}.success`
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminTreeActions
