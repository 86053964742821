import React, { useEffect, useState } from 'react'
import fields from './inputFields'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Collapse from 'react-bootstrap/Collapse'
import { PulseLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalculator,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

const EuroSmrScoreWidget = ({ apiServer, percentileUrl, outcomesUrl }) => {
  if (!apiServer) apiServer = 'https://eurosmr.lmu-klinikum.de'
  const [values, setValues] = useState({})
  const [result, setResult] = useState({})

  const updateValue = (attribute, newValue) => {
    const newValues = { ...values }
    newValues[attribute] = newValue
    if (newValue === 'n/a') delete newValues[attribute]
    setValues(newValues)
  }

  useEffect(() => {
    if (result?.status === 'completed') drawResult()
  }, [result])

  const buildComponent = (field, _index) => {
    if (field.hasOwnProperty('choices')) {
      return (
        <Select
          id={field.id}
          options={field.choices}
          name={field.id}
          value={values[field.id]}
          placeholder={I18n.t('euro_smr_score_widget.show.select')}
          onChange={e => updateValue(field.id, e.value)}
        />
      )
    } else if (field.hasOwnProperty('type') && field.type === 'radio') {
      return (
        <div className="form-check">
          <label className="rowflex" htmlFor={`${field.id}-yes`}>
            <input
              type="radio"
              name={field.id}
              id={`${field.id}-yes`}
              value="1"
              checked={values[field.id] === '1'}
              onChange={e => updateValue(field.id, e.target.value)}
            />
            {I18n.t('yes')}
          </label>
          <label className="rowflex" htmlFor={`${field.id}-no`}>
            <input
              name={field.id}
              type="radio"
              id={`${field.id}-no`}
              value="0"
              checked={values[field.id] === '0'}
              onChange={e => updateValue(field.id, e.target.value)}
            />
            {I18n.t('no')}
          </label>

          <input
            name={field.id}
            type="radio"
            value="n/a"
            id={`${field.id}-na`}
            checked={typeof values[field.id] === 'undefined'}
            onChange={e => updateValue(field.id, e.target.value)}
          />
          <label className="rowflex" htmlFor={`${field.id}-na`}>
            n/a
          </label>
        </div>
      )
    } else if (field.hasOwnProperty('min')) {
      return (
        <Form.Range
          id={field.id}
          min={field.min}
          max={field.max}
          step={field.step}
          value={values[field.id] || 0}
          onChange={e => updateValue(field.id, e.target.value)}
        />
      )
    }
  }

  const generateRow = (field, index) => {
    return (
      <Row key={index} className="field-row">
        <Col sm={3} className="label-col">
          <label htmlFor={field.id}>
            {I18n.t(`euro_smr_score_widget.parameters.${field.id}`)}
          </label>
        </Col>
        <Col sm={7}>{buildComponent(field, index)}</Col>
        <Col sm={2}>
          <Form.Control
            value={values[field.id] || 'n/a'}
            id={field.id}
            min={field.min}
            max={field.max}
            type={values[field.id] ? 'number' : 'text'}
            onChange={e => {
              updateValue(field.id, e.target.value)
            }}
            onBlur={e => {
              if (e.target.value === '') updateValue(field.id, 'n/a')
              if (e.target.value < field.min) updateValue(field.id, field.min)
              if (e.target.value > field.max) updateValue(field.id, field.max)
            }}
          />
        </Col>
      </Row>
    )
  }

  const submitForm = () => {
    setResult({ status: 'pending' })
    fetch(`${apiServer}/mortality_calculator/calculate.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 'ok') {
          queryResult(data.job_id)
        }
      })
      .catch(err => {
        setResult({ status: 'error', result: err.message })
      })
  }

  const queryResult = job_id => {
    const response = fetch(`${apiServer}/smr_results/${job_id}.json`)
    response
      .then(res => res.json())
      .then(data => {
        switch (data.status) {
          case 'pending':
            setResult(data)
            setTimeout(() => queryResult(job_id), 1000)
            break
          case 'completed':
            setResult({
              ...data,
              result: parseFloat(data.result.replace('[1] ', '')),
            })
            break
          default:
            setResult(data)
        }
      })
  }

  const drawResult = () => {
    var c = document.getElementById('risk-canvas1')
    drawLine({ canvas: c, lineHeight: 1194 })
    var c2 = document.getElementById('risk-canvas2')
    const lh = 960
    var config = { canvas: c2, lineHeight: lh }
    if (result.result < 49) {
      config.lineHeight = lh / 2
      config.yOffset = lh / 2
    }
    drawLine(config)
  }

  const drawLine = config => {
    const { canvas, lineHeight, yOffset = 0 } = config
    const width = canvas.parentElement.offsetWidth
    const height = canvas.parentElement.offsetWidth
    canvas.width = width
    canvas.height = height
    const maxX = 1296
    const imageScale = width / 1758
    const xoffset = 360 * imageScale

    var valPercent = parseFloat(result.result) / 100
    var xpos = valPercent * (imageScale * maxX) + xoffset
    var ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    const scaledYOffset = yOffset * imageScale
    ctx.moveTo(xpos, scaledYOffset)
    const lineYEnd = Math.round(lineHeight * imageScale) + scaledYOffset
    ctx.lineTo(xpos, lineYEnd)
    ctx.lineWidth = 3
    ctx.strokeStyle = 'red'
    ctx.stroke()
    ctx.fillStyle = 'rgba(255, 0, 0, 0.5)'
    ctx.font = '20px sans-serif'
    ctx.fillRect(xpos - 120, lineYEnd - 60, 120, 30)
    ctx.fillStyle = 'white'
    ctx.fillText('Your Patient', xpos - 115, lineYEnd - 40)
  }

  return (
    <div className="euro-smr-score-widget">
      <form>
        <Row>
          <Col md={6}>{fields.map(generateRow)}</Col>
          <Col
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <div className="widget-wrapper margin-small">
              {Object.entries(values).length > 0 ? (
                <>
                  <p className="margin-small">
                    {I18n.t('euro_smr_score_widget.show.selected_parameters')}
                  </p>

                  <Table striped hover={false}>
                    <tbody>
                      {Object.entries(values).map(([key, value]) => {
                        return (
                          <tr key={key}>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <p>
                  {I18n.t('euro_smr_score_widget.show.select_values_to_begin')}
                </p>
              )}
            </div>
            <div className="widget-wrapper margin-small">
              <Button
                variant="primary"
                size="lg"
                className="mb-3"
                onClick={submitForm}
                disabled={
                  Object.keys(values).length === 0 ||
                  result?.status === 'pending'
                }>
                <FontAwesomeIcon icon={faCalculator} />
                &nbsp; {I18n.t('euro_smr_score_widget.show.determine_score')}
              </Button>
            </div>

            {!!result.status && (
              <>
                <div className="widget-wrapper margin-small">
                  <Collapse in={true}>
                    <Card className="mt-3">
                      <Card.Body>
                        <Card.Title>
                          {I18n.t(
                            `euro_smr_score_widget.show.${result.status}`
                          )}
                        </Card.Title>
                        <Card.Text>
                          {result.status === 'pending' && (
                            <PulseLoader
                              loading={result.status === 'pending'}
                              size={5}
                            />
                          )}
                          {result.status === 'error' && (
                            <>
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              &nbsp;{result.result}
                            </>
                          )}
                          {result.status === 'completed' && (
                            <span className="result">
                              {parseFloat(result.result).toFixed(2)}
                            </span>
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Collapse>
                </div>
                {result.status === 'completed' && (
                  <>
                    <canvas
                      id="risk-canvas1"
                      className="percentile-rank"
                      style={{
                        backgroundImage: `url(${percentileUrl})`,
                      }}></canvas>
                    <canvas
                      id="risk-canvas2"
                      className="percentile-rank"
                      style={{
                        backgroundImage: `url(${outcomesUrl})`,
                      }}></canvas>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </form>
    </div>
  )
}
export default EuroSmrScoreWidget
