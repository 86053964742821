import PropTypes from 'prop-types'
import ReviewStatusIcon from './ReviewStatusIcon'

export const ReviewStatus = ({ review_status }) => {
  if (!window.scrivito || !review_status) return null

  return (
    <strong alt={I18n.translate('news_page.details.' + review_status)}>
      <br />
      {I18n.translate('news_page.details.review_status')} &nbsp;
      <ReviewStatusIcon reviewStatus={review_status} />
    </strong>
  )
}

ReviewStatus.propTypes = {
  review_status: PropTypes.oneOf(['waiting', 'accepted', 'rejected', null]),
}

export default ReviewStatus
