import { useTranslation } from 'react-i18next'

export const CareerButton = ({ label, onClick, disabled }) => {
  const [t] = useTranslation()
  const buttonTitle = t('career_overview_page:views.create_new_tooltip')

  return (
    <button
      alt={buttonTitle}
      title={buttonTitle}
      disabled={disabled}
      onClick={onClick}
      className="button-lg ghost-button sel-distinction-color-button-invers button-career">
      {label}
    </button>
  )
}
CareerButton.defaultProps = {
  disabled: false,
}
