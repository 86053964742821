import React from 'react'
import Highlight from './search_page/Highlight'

const PageDetailPreview = ({
  info_line,
  top_caption,
  lower_caption,
  big_image,
  url,
  title,
  query,
}) => {
  return (
    <React.Fragment>
      <p className="news-date">{info_line}</p>
      {big_image && <p className="news-top-head">{top_caption}</p>}
      <h2>
        <a href={url}>
          <Highlight text={title} query={query} />
        </a>
      </h2>
      {big_image && <p className="lower-caption">{lower_caption}</p>}
    </React.Fragment>
  )
}

export default PageDetailPreview
