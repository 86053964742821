import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import ArchiveToggler from './overview_pages/ArchivedToggler'
import {
  createValueLabelHash,
  DATE_FORMAT,
  getUrlParams,
  updateUrlParams,
} from '../shared'
import SearchForm from './overview_pages/SearchForm'
import PageList from './PageList'
import ParamContext from './overview_pages/ParamContext'
import NewDateFilter from './news_filter_component/filters/NewDateFilter'

const i18nScopeName = {
  NewsOverviewPage: 'news',
  EventsOverviewPage: 'events',
  PressOverviewPage: 'press',
}

function CommonPageList({
  obj_class,
  query_params,
  search_path,
  facility,
  categories,
  portal,
}) {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [totalResultsCount, setTotalResultsCount] = useState(0)

  const [searchParams, setSearchParams] = useState({
    q: query_params,
    startDate: '',
    endDate: '',
    selectedCategories: [],
    archived: false,
    facility,
    page: 0,
  })

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const i18nIndexScope = i18nScopeName[obj_class] + '_overview_page.index.'

  const updateSearchParams = updatedItems => {
    const newSearchParams = { ...searchParams, page: 0, ...updatedItems }
    setSearchParams(newSearchParams)
    getResults(newSearchParams)
    updateUrlParams(newSearchParams)
  }

  const updateQuery = value => {
    updateSearchParams({ q: value })
  }

  useEffect(() => {
    if (window.location.search.startsWith('?')) {
      const newSearchParams = getUrlParams(Object.keys(searchParams))
      if (typeof newSearchParams.selectedCategories === 'string') {
        newSearchParams.selectedCategories =
          newSearchParams.selectedCategories.split(',')
      }

      if (Object.keys(newSearchParams).length > 0) {
        updateSearchParams(newSearchParams)
      } else {
        getResults()
      }
    } else {
      getResults()
    }
    return () => clearTimeout(timeoutID.current)
  }, [])

  const handleDateChange = (event, picker) => {
    updateSearchParams({
      startDate: picker.startDate.format(DATE_FORMAT),
      endDate: picker.endDate.format(DATE_FORMAT),
    })
  }

  const handleDateClear = () => {
    updateSearchParams({ startDate: '', endDate: '' })
  }

  const getResults = newSearchParams => {
    if (!newSearchParams) newSearchParams = searchParams

    setLoading(true)
    const category_key = portal ? 'portal_categories' : 'categories'

    $.get({
      url: search_path,
      dataType: 'json',
      data: {
        q: newSearchParams.q,
        archived: newSearchParams.archived,
        facility: newSearchParams.facility,
        filter: {
          date: {
            startDate: newSearchParams.startDate ? newSearchParams.startDate : '1900-01-01',
            endDate: newSearchParams.endDate ? newSearchParams.endDate : '2100-01-01',
          },
          [category_key]: newSearchParams.selectedCategories,
        },
        page: newSearchParams.page,
      },
      success: formatResponse,
      error: resultError,
    })
  }

  const formatResponse = response => {
    setLoading(false)

    if (loadingMore) {
      setLoadingMore(false)
      const mergedResults = [...results, ...response.results]
      setResults(mergedResults)
    } else {
      setResults(response.results)
    }
    setTotalResultsCount(response.total_results_count)
  }

  const resultError = () => {
    setLoading(false)
  }

  const handleCategoriesChange = selectedOptions => {
    let options = selectedOptions ? selectedOptions.map(k => k.value) : []
    updateSearchParams({ selectedCategories: options })
  }

  const loadMoreResults = () => {
    setLoadingMore(true)
  }

  useEffect(() => {
    if (loadingMore) updateSearchParams({ page: searchParams.page + 1 })
  }, [loadingMore])

  const handleClearFilter = () => {
    updateSearchParams({
      q: '',
      startDate: '',
      endDate: '',
      more: false,
      selectedCategories: [],
    })
    startDateRef.current.value = '';
    endDateRef.current.value = '';
  }
  const setArchived = value => updateSearchParams({ archived: value })

  return (
    <ParamContext.Provider
      value={{ archived: searchParams.archived, setArchived }}>
      <div className="row">
        <div className="col-md-12 filter-area">
          <ArchiveToggler
            i18nIndexScope={i18nIndexScope}
            archived={searchParams.archived === 'true'}
          />
          <div className="col-md-3 search">
            <SearchForm
              className="pm-placeholder"
              defaultQuery={searchParams.q}
              performSearch={updateQuery}
              placeholder={I18n.t(i18nIndexScope + 'search_placeholder')}
            />
          </div>
          <div className="col-md-6 date">
            <NewDateFilter
              searchFilters={searchParams}
              updateSearchFilters={updateSearchParams}
              startDateRef={startDateRef}
              endDateRef={endDateRef}/>
          </div>
          {categories.length > 0 && (
            <div className="col-md-3 category">
              <Select
                styles={CustomStyles}
                components={{ DropdownIndicator }}
                placeholder={I18n.t('category')}
                value={createValueLabelHash(searchParams.selectedCategories)}
                options={createValueLabelHash(categories)}
                onChange={handleCategoriesChange}
                isMulti
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
          )}
          {Object.entries(searchParams).find(
            ([key, value]) => value?.length
          ) ? (
            <div className="row">
              <div className="col-md-12">
                <a
                  className="filter-delete cursor-pointer"
                  onClick={handleClearFilter}>
                  {I18n.t('clear_filter')}
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div>
        {!!results.length && !loading && (
          <p>{I18n.t('results_n', { n: totalResultsCount })}</p>
        )}
        <PageList
          results={results}
          query={searchParams.q}
          archived={searchParams.archived === 'true'}
        />
        {!results.length && !loading && (
          <h3 className="no-results">{I18n.t('no_results')}</h3>
        )}
        {loading && (
          <div className="text-center">
            <i className="fa fa-2x fa-spinner fa-spin"></i>
          </div>
        )}

        {results.length < totalResultsCount && (
          <button
            className="button button-lg ghost-button sel-gray-button-invers back-button"
            onClick={loadMoreResults}>
            {I18n.t('more')}
          </button>
        )}
      </div>
    </ParamContext.Provider>
  )
}

CommonPageList.defaultProps = {
  categories: [],
  obj_class: 'NewsPage',
}

CommonPageList.propTypes = {
  categories: PropTypes.array,
  facility: PropTypes.string,
  obj_class: PropTypes.string.isRequired,
  query_params: PropTypes.string,
  search_path: PropTypes.string,
}

const CustomStyles = {
  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
}

const DropdownIndicator = ({ innerRef, innerProps }) => {
  return (
    <div className="react-select__indicator">
      <i ref={innerRef} className="fas fa-caret-down" {...innerProps} />
    </div>
  )
}

export default CommonPageList
