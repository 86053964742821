import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { DropdownIndicator, CustomStyles } from '../sharedComponents'

const VALID_FIELDS = ['subjectThema', 'katCategories']

function ChristophorusFilters({ results, onChange }) {
  const [entries, setEntries] = useState({})
  const [selectedEntries, setSelectedEntries] = useState({})

  useEffect(() => {
    onChange(selectedEntries)
  }, [selectedEntries])

  useEffect(() => {
    let newEntries = {}
    results.forEach(element => {
      VALID_FIELDS.forEach(fieldName => {
        if (!newEntries[fieldName]) {
          newEntries[fieldName] = []
        }
        const value = element[fieldName]
        if (typeof value === 'string') {
          newEntries = addEntry(value, fieldName, newEntries)
        } else {
          value.forEach(entry => {
            newEntries = addEntry(entry, fieldName, newEntries)
          })
        }
      })
    })
    setEntries(newEntries)
  }, [results])

  const addEntry = (value, fieldName, newEntries) => {
    if (!newEntries[fieldName].includes(value)) {
      newEntries[fieldName].push(value)
    }
    return newEntries
  }

  const valueHash = values => {
    if (!values) return []
    return values.map(val => {
      return { value: val, label: val }
    })
  }

  const handleSelect = (values, key) => {
    const newEntries = { ...selectedEntries }
    if (!values.length) {
      delete newEntries[key]
    } else {
      newEntries[key] = values.map(item => item.value)
    }
    setSelectedEntries(newEntries)
  }

  if (!Object.keys(entries).length) return null

  return Object.entries(entries).map(([key, values], index) => {
    return (
      <div key={key} className="col-md-3 category">
        <Select
          styles={CustomStyles}
          components={{ DropdownIndicator }}
          placeholder={I18n.t(`christophorus.filters.${key}`)}
          options={valueHash(values)}
          value={valueHash(selectedEntries[key])}
          onChange={e => handleSelect(e, key)}
          isMulti
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    )
  })
}

export default ChristophorusFilters
