import React from 'react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import KumpassForm from './KumpassForm'
import Checkbox from './Checkbox'

export const KumpassSearchForm = ({
  children,
  onParamUpdate,
  onInputUpdate,
  defaultQuery,
  show_symptoms,
  show_diagnostics,
  show_facilities,
  search_path,
}) => {
  const [q, setQ] = useState(defaultQuery)
  const [showSymptoms, setShowSymptoms] = useState(show_symptoms)
  const [showDiagnostics, setShowDiagnostics] = useState(show_diagnostics)
  const [showFacilities, setShowFacilities] = useState(show_facilities)

  const onChange = newValue => {
    setQ(newValue)
    if (!onInputUpdate) return
    onInputUpdate({
      q: newValue,
      showDiagnostics,
      showFacilities,
      showSymptoms,
    })
  }

  useEffect(() => {
    if (q !== defaultQuery) setQ(defaultQuery)
  }, [defaultQuery])

  useEffect(() => {
    if (!onParamUpdate) return
    onParamUpdate({ q, showSymptoms, showFacilities, showDiagnostics })
  }, [showSymptoms, showFacilities, showDiagnostics])

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="clearfix tc" id="search">
          <form action={search_path} acceptCharset="UTF-8" method="get">
            <div className="kumpass-form">
              <KumpassForm defaultQuery={q} onChange={onChange} />
              {children}
            </div>

            <div className="kumpass-types">
              <div className="category_filter_wrapper">
                <Checkbox
                  name="show_symptoms"
                  label="Symptome &amp; Erkrankungen"
                  checked={showSymptoms}
                  className="orange_border"
                  onChange={() => setShowSymptoms(!showSymptoms)}
                />
                <Checkbox
                  name="show_facilities"
                  label="Einrichtungen"
                  checked={showFacilities}
                  className="blau_border"
                  onChange={() => setShowFacilities(!showFacilities)}
                />
                <Checkbox
                  name="show_diagnostics"
                  label="Diagnostik &amp; Therapien"
                  checked={showDiagnostics}
                  className="gruen_border"
                  onChange={() => setShowDiagnostics(!showDiagnostics)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

KumpassSearchForm.defaultProps = {
  search_path: '/',
}

KumpassSearchForm.propTypes = {
  children: PropTypes.any,
  defaultQuery: PropTypes.string,
  onInputUpdate: PropTypes.func,
  onParamUpdate: PropTypes.func,
  search_path: PropTypes.string,
  show_diagnostics: PropTypes.bool,
  show_facilities: PropTypes.bool,
  show_symptoms: PropTypes.bool,
}
