import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import ParamContext from './ParamContext'

const ArchiveToggler = ({ type }) => {
  const { archived, setArchived } = useContext(ParamContext)
  const i18nIndexScope =
    type == 'event'
      ? 'events_overview_page.index.'
      : 'news_overview_page.index.'

  const toggleArchived = () => {
    setArchived(!archived)
  }

  const labelFlag = archived ? 'current' : 'archive'

  return (
    <div className="archive-link-box">
      <a
        onClick={toggleArchived}
        title={I18n.t(i18nIndexScope + labelFlag + '_title')}
        target="_self"
        className="archive-link">
        <i className={`far fa-${archived ? 'eye' : 'archive'}`}></i>
        {I18n.t(i18nIndexScope + labelFlag + '_title')}
      </a>
    </div>
  )
}

ArchiveToggler.defaultProps = {
  archived: false,
}

ArchiveToggler.propTypes = {
  i18nIndexScope: PropTypes.string,
  archived: PropTypes.bool,
  toggleArchived: PropTypes.func,
}

export default ArchiveToggler
