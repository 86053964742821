import React from 'react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { updateUrlParams } from './../shared'
import { PulseLoader } from 'react-spinners'
import { KumpassResultList } from './kumpass/KumpassResultList'
import { KumpassSearchForm } from './kumpass/KumpassSearchForm'

export function KumpassOverviewPage({
  kumpass_introduction_text,
  kumpass_introduction_headline,
  search_path,
  query,
  ...rest
}) {
  const [q, setQ] = useState(query)
  const [hits, setHits] = useState({})
  const [loading, setLoading] = useState(false)
  const [noEntries, setNoEntries] = useState(false)

  const getResults = params => {
    setLoading(true)
    setNoEntries(false)

    const searchParams = {
      q: params.q,
      show_symptoms: params.showSymptoms,
      show_facilities: params.showFacilities,
      show_diagnostics: params.showDiagnostics,
    }

    fetch(`${search_path}?${new URLSearchParams(searchParams)}`)
      .then(parseQueryResponse)
      .catch(logError)

    if (Object.values(params).find(val => val)) {
      updateUrlParams(searchParams)
    }
  }

  const parseQueryResponse = response => {
    setLoading(false)

    if (!response.headers.get('content-type')?.includes('application/json')) {
      console.warn('Bad API response')
      return
    }

    response
      .json()
      .then(result => {
        const sortedData = {}
        result.forEach(entry => {
          let firstLetter = entry.title
            .replace(/(<([^>]+)>)/gi, '')
            .substr(0, 1)
            .toUpperCase()
          if (!sortedData[firstLetter]) sortedData[firstLetter] = []
          sortedData[firstLetter].push(entry)
        })

        setHits(sortedData)
        setNoEntries(!Object.entries(sortedData).length)
      })
      .catch(logError)
  }

  const logError = ({ message }) => console.warn(message)

  const createKumpassPage = klass => {
    scrivito.create_obj({ _obj_class: klass }).then(({ id }) => {
      window.location.href = '/' + id
    })
  }

  const onInputUpdate = details => {
    setHits({})
    setQ(details.q)
    getResults(details)
  }

  const onParamUpdate = details => {
    getResults(details)
    setQ(details.q)

    const { showSymptoms, showFacilities, showDiagnostics } = details
    const wanteds = [showSymptoms, showFacilities, showDiagnostics].filter(
      entry => entry
    )
    if (!wanteds.length || wanteds.length >= 3) {
      setHits({})
      return
    }

    prefilterHits(details)
  }

  // pre-filters existing results while rest is loaded from backend
  const prefilterHits = details => {
    const { showSymptoms, showFacilities, showDiagnostics } = details
    const newHits = {}
    Object.entries(hits).forEach(([key, entries]) => {
      entries.forEach(entry => {
        if (
          (showSymptoms && entry.color === 'gruen') ||
          (showFacilities && entry.color === 'blau') ||
          (showSymptoms && entry.color === 'orange')
        ) {
          if (!newHits[key]) newHits[key] = []
          newHits[key].push(entry)
        }
      })
    })

    if (Object.keys(newHits).length) setHits(newHits)
  }

  useEffect(() => {
    if (q !== query) setQ(query)
  }, [query])

  const hasHits = !!Object.keys(hits).length

  return (
    <div>
      <KumpassSearchForm
        onParamUpdate={onParamUpdate}
        onInputUpdate={onInputUpdate}
        defaultQuery={query}
        {...rest}>
        <div className="loading-indicator">
          <PulseLoader size={5} color="#666666" loading={loading && hasHits} />
        </div>
      </KumpassSearchForm>

      {scrivito.in_editable_view() && (
        <div className="row">
          <div className="col-md-12">
            <button
              onClick={() => createKumpassPage('KumpassFacilityPage')}
              className="ghost-button intern-button button-thin">
              <i className="far fa-file"></i> Einrichtung
            </button>
            <button
              onClick={() => createKumpassPage('KumpassTherapyPage')}
              className="ghost-button intern-button-therapy button-thin">
              <i className="far fa-file"></i> Diagnostik
            </button>
            <button
              onClick={() => createKumpassPage('KumpassSymptomPage')}
              className="ghost-button intern-button-symptom button-thin">
              <i className="far fa-file"></i> Symptom
            </button>
          </div>
        </div>
      )}

      <KumpassResultList
        q={q}
        hits={hits}
        loading={loading}
        hasHits={hasHits}
        noEntries={noEntries}>
        {!q && (
          <>
            <h1>{parse(kumpass_introduction_headline)}</h1>
            <div className="small-text">{parse(kumpass_introduction_text)}</div>
          </>
        )}
      </KumpassResultList>
    </div>
  )
}

KumpassOverviewPage.propTypes = {
  kumpass_introduction_headline: PropTypes.string,
  kumpass_introduction_text: PropTypes.string,
  query: PropTypes.string,
  search_path: PropTypes.string,
  show_diagnostics: PropTypes.string,
  show_facilities: PropTypes.string,
  show_symptoms: PropTypes.string,
}

export default KumpassOverviewPage
