import React from 'react'
import Highlight from './search_page/Highlight'

export const TeaserSnippet = ({
  search_snippet,
  teaser,
  query,
  url,
  children,
}) => {
  return (
    <p className="news-search-snippet news-teaser-text">
      <Highlight text={search_snippet || teaser} query={query} />
      <a href={url}>
        <i className="fas fa-chevron-right" />
      </a>
      {children}
    </p>
  )
}

export default TeaserSnippet
