import { DropdownIndicator, CustomStyles } from '../sharedComponents'
import Select from 'react-select'

export const SearchSelect2 = ({ filter, value, onChangehandler }) => {
  const { name, options } = filter

  const keyValueOptions = options.map(option => {
    return { value: option, label: option }
  })

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      styles={CustomStyles}
      name={name}
      components={{ DropdownIndicator }}
      placeholder={I18n.t('filter_names.' + name)}
      isMulti
      options={keyValueOptions}
      onChange={onChangehandler}
      value={value || []}
    />
  )
}

export default SearchSelect2
