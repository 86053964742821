import URI from 'urijs'

export const keyConversion = (hash, keymap) => {
  let converted_hash = {}
  for (let key in keymap) {
    if (hash.hasOwnProperty(key)) {
      converted_hash[keymap[key]] = hash[key]
    }
  }
  return converted_hash
}

export const setQueryStringValue = (key, value) => {
  const uri = new URI(window.location)
  if (value === null || value === [] || value === '') {
    uri.removeSearch(key)
    window.history.replaceState({ path: uri.href() }, '', uri.href())
    return
  }
  uri.setSearch(key, value)
  window.history.replaceState({ path: uri.href() }, '', uri.href())
}
