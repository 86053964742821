import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment/moment'
import { setCookie } from './components/utils'

/**
 * Make a post fetch to serviceEndpoint with optional data as url params
 * @param endpoint string
 * @param data object
 * @returns promise
 */
export function fetchPost(endpoint, data) {
  return apiFetch(endpoint, data, 'post')
}

/**
 * Generic fetcher
 * @param {string} endpoint
 * @param {object} data
 * @param {string} method | post, get, put, patch, delete
 * @returns Promise
 */
export function apiFetch(endpoint, data, method = 'GET') {
  const reqParams = {
    method: method.toUpperCase(),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        .attributes['content'].value,
      Accept: 'application/json',
    },
    credentials: 'same-origin',
  }

  if (data) reqParams.body = new URLSearchParams(data)
  return fetch(endpoint, reqParams)
}

/**
 * Transforms elements from list to object with keys "value" and "label", each containing element value
 * @param {array} list
 * @returns array
 */
export function createValueLabelHash(list) {
  if (!list.map) return list
  return list.map(item => {
    return { value: item, label: item }
  })
}

/**
 * Adds elements that are true or not empty to pathname.location
 * @param {object} params
 */
export function updateUrlParams(params) {
  if(!params || Object.keys(params).length === 0) return
  if(params.filters) params.filters = JSON.stringify(params.filters)
  const newQuery = formQueryString(params)
  let np = window.location.pathname
  if (newQuery) np += `?${newQuery}`
  history.replaceState(null, '', np)
  setCookie(`page_params`, JSON.stringify(params), 1)
}

/**
 * Searches for each key in wantedKeys in document search params and returns result in proper formatting
 * @param {array} wantedKeys
 * @returns object with key value pair of url params
 */
export function getUrlParams() {
  const params = new URLSearchParams(window.location.search)
  const result = {}
  params.forEach((value, key) => {
    result[key] = value
  })
  Object.entries(result).forEach(([key, myValue]) => {
    if (myValue) {
      result[key] = ['true', 'false'].includes(myValue) ? Boolean(myValue) : myValue
      switch(key){
        case 'categories':
        case 'target_groups':
        case 'course_building_blocks':
        case 'course_learning_formats':
          result[key] = myValue.split(',').map(item => { return { value: item, label: item } } ) // for the SearchSelect2 component
          break
        }
    }
  })
  return result
}

/**
 * Returns a query string from params
 * @param {object} params
 * @returns
 */
export function formQueryString(params) {
  const trueElements = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (value && (value.toString() === 'true' || value.length > 0))
      trueElements.append(key, value)
  })
  if (trueElements.toString() !== '') return trueElements.toString()
  return ''
}

export const dayAndTime = dateString => {
  const date = new Date(dateString)
  const options = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  return date.toLocaleDateString(I18n.locale, options)
}

export const day = dateString => {
  const date = new Date(dateString)
  const options = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  }
  return date.toLocaleDateString(I18n.locale, options)
}

export const monthAndYear = dateString => {
  const date = new Date(dateString)
  const options = {
    month: 'long',
    year: 'numeric',
  }
  return date.toLocaleDateString(I18n.locale, options)
}

export const dayMonthYear = dateString => {
  const date = new Date(dateString)
  const options = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  }
  return date.toLocaleDateString(I18n.locale, options)
}

export const DATE_FORMAT = 'DD.MM.YYYY'

/**
 * @param {string} datestring
 * @returns ISO-formatted datestring, i.e. 2020-02-20
 */
export function parseDate(datestring) {
  return moment(datestring, 'YYYY-MM-DD')
}

/**
 * @param {string} datestring
 * @param {string} targetFormat
 * @param {string} locale
 * @returns ISO-formatted datestring, i.e. 2020-02-20
 */
export function formatDate(
  dateString,
  formatString = DATE_FORMAT,
  locale = 'de'
) {
  return parseDate(dateString).local(locale).format(formatString)
}

/**
 * Validates an e-mail address
 * @param {string} email
 * @returns true or false
 */
export function validEmail(email) {
  return !!email.match(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([äöüÄÖÜa-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

/**
 * Jumps to ref
 * @param {object} ref
 * @returns true or false
 */
export function scrollToTop(ref) {
  if (ref.current) {
    ref.current.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }
}

export function hasPermission (permission) {
  const { dataset } = document.querySelector('body')
  if(dataset?.currentObjPermission){
    const permissions = dataset.currentObjPermission.split(" ")
    return permissions.includes(permission)
  }
  return false
}

export function hasOnePermissionOf (permissions = []) {
  return permissions.find(hasPermission)
}

export const FILTER_ICONS = {
  archived: 'fas fa-box-archive',
  intranet_only: 'fas fa-lock',
  publish_ba: 'fas fa-arrow-up-from-line',
  errors: 'fa-solid fa-triangle-exclamation',
}

export function eventDateString (event) {
  const { start_time, end_time, archived } = event

  if (archived) return dayMonthYear(start_time)

  const startDate = new Date(start_time)
  const endDate = new Date(end_time)

  let startTimeString = formatDate(startDate, 'HH:mm')
  let startDateString = day(start_time)
  let endTimeString = formatDate(endDate, 'HH:mm')

  let endDateString = day(end_time)

  let dateString = startDateString
  if (startTimeString !== '00:00'){
    dateString += `, ${startTimeString}`
  }
  if (startDate.getDate() !== endDate.getDate()) {
    dateString += ` - ${endDateString}`
    if (endTimeString !== '00:00'){
      dateString += `, ${endTimeString}`
    }
  } else {
    if (endTimeString !== '00:00'){
      dateString += ` - ${endTimeString}`
    }
  }


  return dateString
}
