import '@fortawesome/fontawesome-pro/css/all.css'
import '../../assets/stylesheets/icons.css'

// Support component names relative to this directory:
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
var ReactDOM = require('react-dom')
var forge = require('node-forge')
var _ = require('lodash');
