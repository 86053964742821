import React, { useState, useEffect } from 'react'

const EmailValidatorAlert = ({ fieldName }) => {
  const [error, setError] = useState('')

  useEffect(() => {
    const target = document.querySelector(
      `div[data-scrivito-field-name="${fieldName}"]`
    )

    var observer = new MutationObserver(function (_mutations) {
      const value = target.innerText
      if (!value) return
      if (
        !value.match(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
      ) {
        setError('Die E-Mail ist möglicherweise ungültig.')
      } else if (
        value.includes('@med.uni-') &&
        !value.endsWith('@med.uni-muenchen.de')
      ) {
        setError('Meinten Sie @med.uni-muenchen.de?')
      } else {
        setError('')
      }
    })

    observer.observe(target, {
      attributes: true,
      childList: true,
      characterData: true,
    })
  }, [])

  if (!error) return null

  return (
    <div
      style={{ marginBottom: '0', padding: '12px' }}
      className="alert alert-warning">
      <i className="fa fa-exclamation-triangle" />
      &nbsp;
      {error}
    </div>
  )
}

EmailValidatorAlert.defaultProps = {
  fieldName: 'email',
}

export default EmailValidatorAlert
