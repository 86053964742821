import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations from './import_translations'

const { lang } = document.documentElement
const currentLanguage = lang ? lang.substr(0, 2) : 'de'

i18n.use(initReactI18next).init({
  resources: translations,
  lng: currentLanguage,
  fallbackLng: 'de',
  interpolation: {
    prefix: '%{',
    suffix: '}',
    prefixEscaped: '%\\{',
    suffixEscaped: '\\}',
  },
})

export default i18n
