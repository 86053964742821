import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

const DateFilter = ({
  dateFormat,
  startDate,
  endDate,
  handleDateChange,
  handleDateClear,
}) => {
  const datepickerProps = {
    locale: {
      daysOfWeek: I18n.t('date.abbr_day_names'),
      format: dateFormat,
      cancelLabel: I18n.t('cancel'),
      applyLabel: I18n.t('apply'),
      firstDay: 1,
    },
    endDate: endDate || moment().format(dateFormat),
    startDate: startDate || moment().format(dateFormat),

    showDropdowns: true,
  }

  const renderDate = (startDate, endDate) => {
    if (startDate && endDate) {
      return `${startDate} - ${endDate}`
    } else {
      return ''
    }
  }

  return (
    <DateRangePicker
      initialSettings={datepickerProps}
      onApply={handleDateChange}
      onCancel={handleDateClear}>
      <input
        readOnly
        name="daterange"
        className="form-control"
        value={renderDate(startDate, endDate)}
        placeholder={I18n.t('date_placeholder')}
        autoComplete="off"
      />
    </DateRangePicker>
  )
}

DateFilter.defaultProps = {
  dateFormat: 'DD.MM.YYYY',
}

DateFilter.propTypes = {
  dateFormat: PropTypes.string,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleDateChange: PropTypes.func,
  handleDateClear: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default DateFilter
