import React, { useEffect, useState } from 'react'
import { PulseLoader } from 'react-spinners'
import { useApiResponse } from '../../hooks'
import ApiResponse from '../admin_dashboard_page/ApiResponse'
import './dkz-selector.scss'

export const DKZSelector = ({ jobOfferType }) => {
  const [path, setPath] = useState('')
  const [q, setQ] = useState('')
  const [response, progress, _error] = useApiResponse(path)
  const [results, setResults] = useState([])

  useEffect(() => {
    if (!response) return

    response.json().then(setResults)
  }, [response])

  const setFieldValue = () => {
    const { value } = document.getElementById('dkzs')
    const element = document.querySelector(
      "div[data-scrivito-field-name='title_code']"
    )
    element.innerText = value
    element.focus()

    const nameField = document.querySelector('.title_code_name')
    const item = results.find(item => item.job_id === value)
    nameField.innerText = `${item.name} (${item.typ})`
  }

  useEffect(() => {
    let timer1
    if (q && q.length > 2) {
      timer1 = setTimeout(
        () =>
          setPath(
            `/api/v1/career/dkzs.json?q=${q}&job_offer_type=${jobOfferType}`
          ),
        800
      )
    }
    return () => {
      clearTimeout(timer1)
    }
  }, [q])

  return (
    <div className="dkz-selector">
      <div className="searcher">
        <input
          id="q"
          onChange={e => setQ(e.currentTarget.value)}
          placeholder="Beruf eingeben..."
        />
        <PulseLoader size={5} color="#666666" loading={progress} />
        <br />
        {response && response.status !== 200 && (
          <ApiResponse response={response} />
        )}
      </div>

      {!!results.length && (
        <select id="dkzs" size={10} onChange={setFieldValue}>
          {results.map(job => (
            <option value={job.job_id} key={job.job_id}>
              {job.name} ({job.typ})
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

export default DKZSelector
