import { SEARCH_CHECKBOXES, SEARCH_DROPDOWNS } from '../career_utils'
import FilterCheckbox from './FilterCheckbox'
import { SearchSelect } from './SearchSelect'

export const ApiFilter = ({ updateValue, values }) => {
  const { intranet_request, logged_in } = window.DREAMin

  if (!values) return null
  let checkboxes = []
  if (scrivito.in_editable_view()) {
    checkboxes = SEARCH_CHECKBOXES
  } else if (intranet_request || logged_in){
      checkboxes = ['intranet_only']
  }

  return (
    <>
      <div className="row filter-area">
        {SEARCH_DROPDOWNS.map(attribute => (
          <div className="col-md-3 category" key={attribute}>
            <SearchSelect
              key={attribute}
              field={attribute}
              onChangehandler={updateValue}
              selectedOptions={values[attribute]}
            />
          </div>
        ))}
      </div>

      {!!checkboxes.length && (
        <div className="row filter-area-intranet">
          {checkboxes.map((attribute, index) => (
            <FilterCheckbox
              key={index}
              attribute={attribute}
              updateValue={updateValue}
              value={values[attribute]}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default ApiFilter
