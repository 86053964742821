import { useTranslation } from 'react-i18next'
import { DropdownIndicator, CustomStyles } from '../sharedComponents'
import Select from 'react-select'

export const SearchSelect = ({ field, selectedOptions, onChangehandler }) => {
  const [t] = useTranslation()
  const options = window.DREAMin.career[`${field}_filter_options`]

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      styles={CustomStyles}
      components={{ DropdownIndicator }}
      placeholder={t('career_overview_page:filter_names.' + field)}
      isMulti
      options={options}
      onChange={values => onChangehandler(field, values)}
      value={selectedOptions}
    />
  )
}
