import Highlight from 'components/search_page/Highlight'
import {
  translate
} from 'components/TranslationProvider'

class Hit extends React.Component {

  klassSelect(klass) {
    if (this.props.show_legacy_categories == true) {
      if (klass == 'FionaOldSchoolSearchObj') {
        return (
          <div>
            <span className='category-title fiona-result'>
              <span>KLINIKUM KLASSIK</span>
            </span>
          </div>
        )
      } else {
        return (<div>

          <span className='category-title scrivito-result'>
            <span>KLINIKUM 2.0</span>
          </span>
        </div>)
      }
    }
  }

  pdf_icon(klass, link) {
    if (klass == 'Pdf') {
      return(<i className="fas fa-file-pdf pdf"></i>)
    }
    if (klass == 'FionaOldSchoolSearchObj' && link.endsWith('.pdf')) {
      return(<i className="fas fa-file-pdf pdf"></i>)
    }
  }

  glyphPosition(body) {
    if (body != '') {
      return (
        <span alt="Mehr" className="fas fa-chevron-right" title="Suchen"></span>
      )
    }
  }

  renderHit(cluster_name) {
    if (cluster_name == 'kumpass') {
      return (
        <div>
          <h2>
            <a href={this.props.link}>
              <Highlight text={this.props.title} query={this.props.query} />
            </a>
          </h2>
          <p>
            <Highlight text={this.props.body} query={this.props.query} />
            {this.glyphPosition(this.props.body, 'bottom')}
          </p>
        </div>)
    } else if (cluster_name == 'events') {
      return (<div className="search-hits">
        <div className="search-hit">

          <div className="event-date-box">
            <div className="date-box">
              {this.props.short_date_day}
            </div>
          </div>

          <div className="event-detail-box">
            <h2>
              <a href={this.props.link}>
                {this.props.title}
              </a>
            </h2>
            <p>{this.props.location}</p>
            <div className="event-infos">
              <div className="event-detail">{this.props.date_category}</div>
            </div>
          </div>
        </div>
      </div>)
    } else if (cluster_name == 'news') {

      return (<div>
        <span className='news-dates'>{this.props.date_category}</span>
        <h2>
          <a href={this.props.link}>
            <Highlight text={this.props.title} query={this.props.query} />
          </a>
        </h2>
      </div>)
    } else if (cluster_name == 'career') {
      return (
        <div>
          <h2>
            <a href={this.props.link}>
              <Highlight text={this.props.title} query={this.props.query} />
            </a>
          </h2>
        </div>)
    }
  }

  showResult() {
    if (this.props.cluster_name == 'all') {
      return (
        <div>
          {this.klassSelect(this.props.class)}
          <div>
            <h2>
              <a href={this.props.link}>
                {this.pdf_icon(this.props.class, this.props.link)}
                <Highlight text={this.props.title} query={this.props.query} />
              </a>
            </h2>
            <p>
              <Highlight text={this.props.body} query={this.props.query} />
              <a href={this.props.link}>
                {this.glyphPosition(this.props.body)}
              </a>
            </p>
          </div>
        </div>)
    } else {
      return (
        <div>
          {this.renderHit(this.props.cluster_name)}
        </div>

      )
    }
  }
  render() {
    const t = this.props.t
    return (
      <div className='result'>
        {this.showResult()}
      </div>

    )
  }
}
export default translate(['Hit'])(Hit)
