import React, { useEffect, useState } from 'react'
import { PulseLoader } from 'react-spinners'
import './WorkspaceDataGrid-style.scss'

export const WorkspaceDataGrid = () => {
  const [workspaces, setWorkspaces] = useState([])
  const [homepages, setHomepages] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')

  useEffect(() => {
    setLoading(true)
    setLoadingMessage(I18n.t('loading_workspaces_and_homepages'))
    fetch('/api/v1/admin/workspace.json')
      .then(response => response.json())
      .then(data => setWorkspaces(data))

    fetch('/api/v1/admin/homepage.json')
      .then(response => response.json())
      .then(data => {
        setHomepages(data)
      })
  }, [])

  useEffect(() => {
    const workspaceStub = workspaces.find(workspace => !workspace.members)
    if (workspaceStub) {
      setLoadingMessage(I18n.t('loading_workspace_details'))

      fetch(`/api/v1/admin/workspace/${workspaceStub.id}.json`)
        .then(response => response.json())
        .then(data => {
          const newWorkspaces = workspaces.map(workspace => {
            if (workspace.id === data.id) {
              return data
            }
            return workspace
          })
          setWorkspaces(newWorkspaces)
        })
        .catch(error => {
          console.error(error)
          // remove workspace from list
          const newWorkspaces = workspaces.filter(
            workspace => workspace.id !== workspaceStub.id
          )
          setWorkspaces(newWorkspaces)
        })
    } else if (workspaces.length > 0) {
      setLoadingMessage('')
      setLoading(false)
      window.DREAMin.adminDashboard.onWorkspacesLoadComplete()
    }
  }, [workspaces])

  const columns = [
    { field: 'title', headerName: I18n.t('title') },
    {
      field: 'members',
      headerName: I18n.t('admin_dashboard_page.workspaces.user_permission'),
      width: 130,
    },
    { field: 'create_date', headerName: I18n.t('create_date') },
    {
      field: 'last_changed_date',
      headerName: I18n.t('last_changed_date'),
      width: 130,
    },
    { field: 'changes', headerName: I18n.t('changes') },
  ]

  const getTotals = changes => {
    if (!changes) return 0
    return Object.values(changes).reduce((acc, cur) => acc + cur, 0)
  }

  const fieldRenderer = (workspace, fieldName) => {
    const field = workspace[fieldName]
    if (!field) return null
    switch (fieldName) {
      case 'create_date':
      case 'last_changed_date':
        return new Date(field).toLocaleString('de-DE', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
      case 'members':
        return field.map(member => {
          return (
            <div key={member.email}>
              {member.email}
              <br />
              <span className="small">
                {hpStateRenderer(member.permission)}
              </span>
            </div>
          )
        })
      case 'changes':
        const totalCount = getTotals(field)
        return (
          <div>
            <strong>{totalCount}</strong>
            &nbsp;
            {Object.entries(field).map(([key, value]) => (
              <span key={key}>
                {`${key.substring(0, 1).toUpperCase()}:${value}`}&nbsp;
              </span>
            ))}
          </div>
        )
    }

    return field
  }

  const hpStateRenderer = permissions => {
    if (!homepages) return permissions.join(', ')
    return permissions.map(permission => {
      const homepage = homepages.find(hp => hp.permission_id === permission)
      if (!homepage) return <span>{permission} </span>
      return (
        <React.Fragment key={permission}>
          <span className={homepage.hidden === 'yes' ? 'red' : 'green'}>
            {permission}
          </span>{' '}
        </React.Fragment>
      )
    })
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <h1>
          {I18n.t('admin_dashboard_page.workspaces.title')}{' '}
          {!loading && (
            <sup className="small">
              {I18n.t('admin_dashboard_page.workspaces.in_use_count', {
                count: workspaces.length,
              })}
            </sup>
          )}
        </h1>
        {loading && (
          <div className="alert alert-info">
            <p>
              {loadingMessage}
              &nbsp;
              <PulseLoader size={5} color="#666666" loading={loading} />
            </p>
          </div>
        )}
        <table
          style={{ minHeight: '70vh' }}
          className="workspace-data-grid"
          id="workspace-table">
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {workspaces &&
              Array.isArray(workspaces) &&
              workspaces.map(workspace => (
                <tr key={workspace.id}>
                  {columns.map(column => {
                    let data
                    switch (column.field) {
                      case 'last_changed_date':
                      case 'create_date':
                        data = new Date(workspace[column.field])
                        break
                      case 'changes':
                        data = getTotals(workspace.changes)
                        break
                    }

                    return (
                      <td key={column.field} data-sort={data}>
                        {fieldRenderer(workspace, column.field)}
                      </td>
                    )
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default WorkspaceDataGrid
