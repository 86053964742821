import React, { useContext } from 'react'
import { SearchSelect2 } from './SearchSelect2'
import ParamContext from './ParamContext'

// type SearchFilter = {
//   name: string;
//   options: string[];
//   multiple?: boolean;
//   selectedOptions: string[];
// }

// interface SearchFiltersProps {
//   filters: SearchFilter[];
//   onChangehandler: (name: string, values: string[]) => void;
// }

// export const SearchFilters = ({filters, onChangehandler}: SearchFiltersProps) => {
export const SearchFilters = ({ onChangehandler }) => {
  const onChange = (selectedOptions, item) => {
    onChangehandler(item.name, selectedOptions)
  }

  const { filterObjects, values } = useContext(ParamContext)
  if (!filterObjects) return null

  return filterObjects.map(filter => (
    <div className="col-md-3 category" key={filter.name}>
      <SearchSelect2
        onChangehandler={onChange}
        filter={filter}
        value={values[filter.name]}
      />
    </div>
  ))
}
