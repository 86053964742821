import './hit.scss'
import { StatusIcons } from './StatusIcons'

export const Hit = ({ hit }) => {
  const { career_facts_summary, job_title, url } = hit

  return (
    <div className="career-overview-hit">
      <div className="col-md-9 col-sm-9 career-results">
        <h2>
          <a href={url} className="gtm-job-offer-link">
            {job_title}
            <StatusIcons hit={hit} />
          </a>
        </h2>
        <div className="news-dates">{career_facts_summary}</div>
      </div>

      {scrivito.in_editable_view() && (
        <div className="col-md-3 col-sm-3 float-right">
          <a href={url}>
            <button className="button-lg ghost-button sel-distinction-color-button-invers">
              Bearbeiten
            </button>
          </a>
        </div>
      )}
    </div>
  )
}
export default Hit
