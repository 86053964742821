import PropTypes from 'prop-types'
import React from 'react'

export const ArchiveDetail = ({ published_on, title, url }) => {
  return (
    <div className="archiv-detail">
      <div className="news-date">{published_on}</div>
      <h2>
        <a href={url} title={title}>
          {title}
        </a>
      </h2>
    </div>
  )
}

ArchiveDetail.propTypes = {
  published_on: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default ArchiveDetail
