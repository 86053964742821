const ObjDetailsTable = ({ title, id, path, child_count }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <td>Titel</td>
          <td>
            <strong>{title}</strong>
          </td>
        </tr>
        <tr>
          <td>ID</td>
          <td>{id}</td>
        </tr>
        <tr>
          <td>Path</td>
          <td>{path}</td>
        </tr>
        <tr>
          <td>Anzahl Unterobjekte</td>
          <td>{child_count}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default ObjDetailsTable
