import {formatDate, parseDate} from '../../shared'

export function ChristophorusResult({ result }) {
  return (
    <div className="search-hit">
      <div className={`event-date-box col-md-2 col-xs-5 ${result.katColor}`}>
        <div className="date-box">
          {formatDate(result.datum_von, 'DD MMM', 'de')}
        </div>
      </div>
      <div className="event-detail-box col-md-10 col-xs-10">
        {result.subjectThema} | {result.kurs_nr}
        <h2>
          <a href={`/christophorus-akademie/courses/${result.id}?appointment_id=${result.appointmentId}`}>{result.titel}</a>
        </h2>
        <div className="event-infos">
          <div className="event-detail">
            {formatDate(result.datum_von, 'D. MMM YYYY', 'de')}
            {parseDate(result.datum_von).isSame(parseDate(result.datum_bis))
              ? ''
              : ` - ${formatDate(result.datum_bis, 'D. MMM YYYY', 'de')}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChristophorusResult
