import ReactPaginate from 'react-paginate'

const Pagination = ({ goToPage, count, limit, forcePage }) => {
  const pageClickHandler = ({ selected }) => {
    goToPage(selected)
  }

  const pageCount = Math.ceil(count / limit)

  if (pageCount <= 1)
    return <div className="pagination-area" style={{ height: '36px' }}></div>

  return (
    <ReactPaginate
      previousLabel={<i className="fas fa-chevron-left" />}
      nextLabel={<i className="fas fa-chevron-right" />}
      containerClassName="pagination-area"
      breakLabel={<div className="pagination-number">von {pageCount}</div>}
      forcePage={forcePage}
      pageCount={pageCount}
      marginPagesDisplayed={0}
      pageRangeDisplayed={0}
      onPageChange={pageClickHandler}
      activeClassName="pagination-activ"
    />
  )
}
export default Pagination
