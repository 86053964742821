import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment/moment'
import ReactPaginate from 'react-paginate'
import { PulseLoader } from 'react-spinners'
import { formatDate, parseDate } from '../../shared'
import ChristophorusResult from './ChristophorusResult'
import ChristophorusFilters from './ChristophorusFilters'
import SearchForm from '../overview_pages/SearchForm'
import NewDateFilter from '../news_filter_component/filters/NewDateFilter'

moment.updateLocale('de', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
})

ChristophorusItems.defaultProps = { limit: 10 }

export function ChristophorusItems({ limit }) {
  const [results, setResults] = useState([])
  const [filteredResults, setFilteredResults] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({})
  const [dateFilters, setDateFilters] = useState({})
  const [query, setQuery] = useState('')

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  useEffect(() => {
    getResults()
  }, [])

  useEffect(() => {
    setFilteredResults(results)
    setPage(0)
    setFilters({})
    setDateFilters({})
  }, [results])

  const getResults = () => {
    setLoading(true)
    fetch(`/christophorus-akademie/json`, { credentials: 'include' })
      .then(response => response.json())
      .then(lsd => {
        setLoading(false)
        setResults(lsd)
      })
      .catch(e => setLoading(false))
  }

  const pageClickHandler = data => setPage(data.selected)

  useEffect(() => {
    if (!results) return
    let limitedEvents = results
    if (Object.keys(filters).length > 0) {
      limitedEvents = []
      results.forEach(event => {
        const trials = Object.entries(filters).map(([key, values]) => {
          if (typeof event[key] == 'string') {
            return values.includes(event[key]).toString()
          } else {
            return event[key].map(entry => values.includes(entry)).join('')
          }
        })
        if (!trials.find(t => t.includes('false'))) {
          limitedEvents.push(event)
        }
      })
    }
    if (query) {
      limitedEvents = limitedEvents.filter(item =>
        JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      )
    }
    if (Object.keys(dateFilters).length > 0) {
      limitedEvents = limitedEvents.filter(({ datum_von }) => {
        if (dateFilters.startDate && dateFilters.endDate) {
          return dateFilters.startDate <= datum_von && dateFilters.endDate >= datum_von;
        } else if (dateFilters.startDate) {
          return dateFilters.startDate <= datum_von;
        } else if (dateFilters.endDate){
          return dateFilters.endDate >= datum_von;
        } else {
          return true;
        }
      });
    }
    setFilteredResults(limitedEvents)
  }, [filters, results, page, dateFilters, query])

  const showEvents = () => {
    let lastDate = parseDate('2000-01-01')
    const offset = page * limit
    const slicedResults = filteredResults.slice(offset, offset + limit)
    return slicedResults.map((event, index) => {
      let monthIndicator = ''
      const date = parseDate(event.datum_von)
      if (date.month() > lastDate.month() || date.year() > lastDate.year()) {
        monthIndicator = (
          <div className="month">{formatDate(date, 'MMMM YYYY', 'de')}</div>
        )
      }
      lastDate = parseDate(event.datum_von)
      return (
        <React.Fragment key={index + 1}>
          {monthIndicator}
          <ChristophorusResult result={event} />
        </React.Fragment>
      )
    })
  }

  const onFilterChange = list => {
    setFilters(list)
    setPage(0)
  }

  const updateValues = vals => {
    const newValues = { ...dateFilters, ...vals }

    setDateFilters(newValues)
  }

  return (
    <div>
      <div className="bg_white">
        {results && !loading && results.length && (

          <div className="row events-overview-page filter-area">
            <div className="filter-area row">
              <div className="col-md-6 search">
                <SearchForm
                  className="pm-placeholder"
                  inputValue={query}
                  performSearch={setQuery}
                  placeholder="Suchbegriff eingeben..."
                />
              </div>
            </div>
            <div className="filter-area row filters">
              <div className="col-md-6 calendar">
                <NewDateFilter
                  searchFilters={dateFilters}
                  updateSearchFilters={updateValues}
                  startDateRef={startDateRef}
                  endDateRef={endDateRef}/>
              </div>
              <ChristophorusFilters results={results} onChange={onFilterChange} />
            </div>
          </div>
        )}

        <div className="row events-overview-page">
          <div className="col-md-12 events-area">
            <div className="event-group">
              {loading && (
                <div>
                  <b>Veranstaltungen werden geladen...</b>
                  <br />
                  <PulseLoader size={5} color="#666666" loading={loading} />
                </div>
              )}
              <div className="search-hits">{showEvents()}</div>
            </div>
          </div>
          {results && !loading && results.length && (
            <div>
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>}
                nextLabel={
                  <div className="previous">
                    {' '}
                    <i className="fas fa-chevron-right" />
                  </div>
                }
                containerClassName="row pagination-area"
                breakLabel={
                  <div className="pagination-number">
                    von {Math.ceil(filteredResults.length / 10)}
                  </div>
                }
                forcePage={page}
                pageCount={Math.ceil(filteredResults.length / 10)}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                onPageChange={pageClickHandler}
                activeClassName={'pagination-activ'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChristophorusItems
