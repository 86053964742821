import { getUrlParams } from "../shared"
import { getCookie, parseStashedValues } from "./utils"

export const SEARCH_DROPDOWNS = [
  'basic_assignment',
  'time_model',
  'workplace',
  'language_iso'
]

export const SEARCH_CHECKBOXES = ['intranet_only', 'archived', 'publish_ba', 'errors']

export const getValuesFromCaches = () => {
  const wantedParams = [...SEARCH_DROPDOWNS, ...SEARCH_CHECKBOXES, 'q']
  const searchValues = getUrlParams(wantedParams)

  if (Object.keys(searchValues).length) {
    const newValues = parseStashedValues(searchValues)
    return newValues
  }

  return getValuesFromCookie()
}

export const getValuesFromCookie = (cookieName = 'career_search_params') => {
  if (getCookie(cookieName)) {
    const newValues = parseStashedValues(
      JSON.parse(getCookie(cookieName))
    )
    return newValues
  }

  return false
}
