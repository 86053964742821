import { useTranslation } from 'react-i18next'
import { FILTER_ICONS } from '../../shared'

const FilterCheckbox = ({ attribute, value, updateValue }) => {
  const [t] = useTranslation()

  return (
    <div className="col-md-2 category" key={attribute}>
      <div className="filter-intranet">
        <label className="control control-radio square">
          <input
            type="checkbox"
            name={attribute}
            checked={value == 'yes'}
            onChange={() =>
              updateValue(attribute, value == 'yes' ? 'no' : 'yes')
            }
          />
          <div className="control_indicator square"></div>
        </label>
        <div className="filter-description">
          {t(`career_overview_page:filter_names.${attribute}`)}
          &nbsp;
          <i className={FILTER_ICONS[attribute]}></i>
        </div>
      </div>
    </div>
  )
}

export default FilterCheckbox
