import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import URI from 'urijs'
import { translate } from 'components/TranslationProvider'

const MIN_QUERY_LENGTH = 3
const DEBOUNCE_MILLISECONDS = 300
const theme = {
  container: 'autosuggest-container',
  containerOpen: 'open',
  input: 'autosuggest-input',
  inputOpen: 'open',
  inputFocused: 'focused',
  suggestionsContainer: 'autosuggest-suggestions-container',
  suggestionsContainerOpen: 'open',
  suggestionsList: 'autosuggest-suggestions-list',
  suggestion: 'autosuggest-suggestion',
  suggestionFirst: 'first',
  suggestionHighlighted: 'highlighted',
  sectionContainer: 'autosuggest-section-container',
  sectionContainerFirst: 'first',
  sectionTitle: 'autosuggest-section-title',
}

class SearchAutoSuggest extends React.Component {
  /*
    props:
      search_page_path
      super_page_id
  */
  constructor() {
    super()
    this.state = {
      value: '',
      oldValue: '',
      suggestions: [],
      loading: false,
    }
    this.renderSuggestion = this.renderSuggestion.bind(this)
    this.getSuggestions = _.debounce(this.getSuggestions, DEBOUNCE_MILLISECONDS)
    this.getSectionSuggestions = this.getSectionSuggestions.bind(this)
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    let parts = parse(suggestion.result, match(suggestion.result, query))
    let partsSpans = parts.map((part, index) => (
      <span key={index} style={part.highlight ? { fontWeight: 'bold' } : {}}>
        {part.text}
      </span>
    ))
    return <div>{partsSpans}</div>
  }

  renderInputComponent = inputProps => {
    if (this.state.loading) {
      inputProps.className += ' loading'
    }
    return (
      <div>
        <input {...inputProps} id="main-search-field" />
        <button id="main-search-submit" type="submit">
          {I18n.t('search.submit_button')}
          <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
        </button>
      </div>
    )
  }

  getSuggestionValue = suggestion => {
    return suggestion.result
  }

  getSuggestions = query => {
    const uri = new URI(`/api/autocomplete/${this.props.super_page_id}`)
    uri.addSearch('q', query)
    $.ajax({
      url: uri,
      dataType: 'json',

      success: function (data) {
        this.setState({
          suggestions: data.clusters,
          oldValue: query,
          loading: false,
        })
      }.bind(this),

      error: function (data) {
        this.setState({ suggestions: [], oldValue: query, loading: false })
      }.bind(this),
    })
  }

  firstWordChanged = query => {
    let queryword = query.match(/\b\w+\b/g)[0]
    let stateword = this.state.value.match(/\b\w+\b/g)[0]

    return queryword !== stateword
  }

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ loading: true, suggestions: [] })
    this.getSuggestions(value)
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  getSectionSuggestions(suggestion) {
    return suggestion.results
  }

  renderSectionTitle(suggestion) {
    return (
      <div>
        {I18n.t(`search.${suggestion.cluster}`)}
        {suggestion.cluster == 'kumpass' && (
          <div className="logo img-responsive"></div>
        )}
      </div>
    )
  }
  onSuggestionSelected = event => {
    const destination = new URI(this.props.search_page_path)
    destination.addSearch('q', event.currentTarget.textContent)
    window.location.href = destination
  }

  handleSubmit = e => {
    e.preventDefault()
    const destination = new URI(this.props.search_page_path)
    destination.addSearch('q', this.state.value)
    window.location.href = destination
  }

  shouldRenderSuggestions = value => {
    return value.trim().length >= MIN_QUERY_LENGTH
  }

  render() {
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: I18n.t('search.placeholder'),
      value,
      type: 'search',
      onChange: this.onChange,
      onBlur: this.onBlur,
    }

    return (
      <form id="main-search" onSubmit={this.handleSubmit}>
        <Autosuggest
          multiSection={true}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          renderSectionTitle={this.renderSectionTitle}
          renderInputComponent={this.renderInputComponent}
          getSectionSuggestions={this.getSectionSuggestions}
          inputProps={inputProps}
          theme={theme}
        />
      </form>
    )
  }
}

export default translate(['SearchAutoSuggest'])(SearchAutoSuggest)
