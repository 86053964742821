import PropTypes from 'prop-types'
import Event from './Event'
import ParamContext from '../overview_pages/ParamContext'
import { useContext, useMemo } from 'react'
import { monthAndYear } from '../../shared'

const EventList = ({ results }) => {
  if (!results || results.length === 0) return 'No events found'

  // API returns results in results.results
  if (results.results) results = results.results

  const { archived } = useContext(ParamContext)

  const elearningEvents = useMemo(() => {
    return results.filter(e => e.e_learning)
  }, [results])

  const regularEvents = useMemo(() => {
    return results.filter(e => {
      return !e.e_learning && e.course_repetition != 'regularly'
    })
  }, [results])

  const ongoingEvents = useMemo(() => {
    return results.filter(e => !e.e_learning && e.course_repetition === 'regularly')
  }, [results])

  const regularEventsByMonth = () => {
    let month = -1
    return regularEvents.map((item, index) => {
      const myMonth = getStartMonth(item)
      let monthDisplay = null

      if (myMonth !== month && !archived) {
        month = myMonth
        monthDisplay = <div className="month">{month}</div>
      }

      return (
        <React.Fragment key={(index + 1).toString()}>
          {monthDisplay}
          {renderEvent(item)}
        </React.Fragment>
      )
    })
  }

  const showSidebar = ongoingEvents.length > 0 || elearningEvents.length > 0

  const firstClass = archived ? 'archiv-list' : 'search-hits'
  const regularEventsClass = showSidebar ? 'col-md-8' : 'col-md-12'
  const recurringEventsClass = regularEvents.length > 0 ? 'col-md-4' : 'col-md-12'

  const renderEvent = item => <Event key={item.fake_key.toString()} item={item} />

  const getStartMonth = item => monthAndYear(item.start_time)

  return (
    <div className={elearningEvents ? 'row' : ''}>
      {regularEvents.length > 0 && <div className={`${firstClass} ${regularEventsClass}`}>{regularEventsByMonth()}</div>}
      {showSidebar && (
        <div className={`${firstClass} ${recurringEventsClass}`}>
          {elearningEvents.length > 0 && (
            <React.Fragment>
              <div className="month">{I18n.t('event_page.details.e_learning')}</div>
              {elearningEvents.map(renderEvent)}
            </React.Fragment>
          )}
          {ongoingEvents.length > 0 && (
            <React.Fragment>
              <div className="month">{I18n.t('event_page.details.regularly')}</div>
              {ongoingEvents.map(renderEvent)}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  )
}

EventList.propTypes = {
  archived: PropTypes.any,
  query: PropTypes.any,
}
EventList.defaultProps = {
  query: '',
  archived: false,
}

export default EventList
