import { I18nContext } from 'react-i18next'

const ApiResponse = ({ response, okMessage }) => {
  if (!response) return null

  const ok = response.status == 200

  const msg = ok
    ? okMessage
    : I18n.translate('admin_dashboard_page.tree_actions.error') +
      ': ' +
      response.statusText

  return (
    <div className={`alert alert-${ok ? 'success' : 'danger'}`}>
      <p>{msg}</p>
    </div>
  )
}

export default ApiResponse
