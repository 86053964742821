import React from 'react'
import Highlight from './Highlight'

export const HitLister = ({ hits, q }) => {
  const createHit = ({ url, title, body }) => {
    return (
      <li key={url} className="result-set">
        <h2>
          <a href={url}>
            <Highlight text={title} query={q} />
          </a>
        </h2>
        <p>
          <Highlight text={body} query={q} />
        </p>
      </li>
    )
  }
  return (
    <div className="blog-result">
      <ul>{hits.map(createHit)}</ul>
    </div>
  )
}

export default HitLister
