import React, { useEffect, useState } from 'react'
import ApiRequestWorker from '../ApiRequestWorker'
import SearchForm from '../overview_pages/SearchForm'

export const BlogSearch = () => {
  const [value, setValue] = useState('')
  const [results, setResults] = useState([])
  const [endpoint, setEndpoint] = useState(false)
  const onSubmit = e => {
    e.preventDefault()
    const inputValue = e.currentTarget.getElementsByTagName('input')[0].value

    location.href = `/editor_dashboard/search?q=${inputValue}`
  }

  const performSearch = v => {
    setValue(v)
  }

  useEffect(() => {
    if (!value || value.length < 3) {
      setResults([])
      return
    }

    setEndpoint(
      `/api/v1/blog/search.json?${new URLSearchParams({
        q: value,
      })}`
    )
  }, [value])

  const onClose = e => {
    e.preventDefault()
    setResults([])
  }

  return (
    <div className="blog-search">
      <SearchForm onSubmit={onSubmit} performSearch={performSearch} />
      <div className="blog-search-results">
        <ApiRequestWorker endpoint={endpoint} setResults={setResults}>
          {results && results.length > 1 && (
            <div className="result-set">
              <div className="results-count">
                <p>{I18n.t('results_n', { n: results.length })}</p>

                <span>
                  <a href="#" onClick={onClose}>
                    <i className="fa fa-close"></i>
                  </a>
                </span>
              </div>

              <ul>
                {results.map(hit => (
                  <li key={hit.id}>
                    <a href={`/${hit.id}`}>{hit.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </ApiRequestWorker>
      </div>
    </div>
  )
}

export default BlogSearch
