import React from 'react'
import PropTypes from 'prop-types'
const icons = { waiting: 'clock', accepted: 'check', rejected: 'times' }

const ReviewStatusIcon = ({ reviewStatus }) => {
  const mycon = reviewStatus ? icons[reviewStatus] : 'accepted'
  return <i className={`fa fa-${mycon}`}></i>
}

ReviewStatusIcon.propTypes = {
  reviewStatus: PropTypes.oneOf(['waiting', 'accepted', 'rejected', null]),
}

export default ReviewStatusIcon
