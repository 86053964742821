import { useEffect, useRef, useState } from 'react'
import { apiFetch } from '../shared'

const ApiRequestWorker = ({ endpoint, setResults, children }) => {
  const [error, setError] = useState(null)
  const [searching, setSearching] = useState(false)
  const [count, setCount] = useState(null)

  const latestEndpoint = useRef(null)

  useEffect(() => {
    if (!endpoint) return

    setSearching(true)
    latestEndpoint.current = endpoint
    apiFetch(endpoint)
      .then(result => {
        setSearching(false)
        if (latestEndpoint.current !== endpoint) return
        result.json().then(data => {
          setCount(data.total_results_count)
          setResults(data)
        })
      })
      .catch(data => {
        setSearching(false)
        setError(data)
      })
  }, [endpoint])

  if (searching) {
    return (
      <div className="text-center mt-4 col-md-8 col-md-offset-2 col-sm-12">
        <br />
        <i className="fa fa-2x fa-spinner fa-spin"></i>
      </div>
    )
  }

  if (error) {
    return <div className="alert alert-problem">{error.message}</div>
  }

  if (count === 0) {
    return (
      <div className="row no-result-box">
        <div className="no-result-note col-md-8 col-md-offset-2 col-sm-12">
          <div className="result-head">{I18n.t(`search.no_results`)}</div>
          <div className="result-body">{I18n.t('search.reset_filters')}</div>
        </div>
      </div>
    )
  }

  return children
}

export default ApiRequestWorker
