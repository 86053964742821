import { useEffect, useRef, useState } from 'react'
import ApiRequestWorker from './ApiRequestWorker'
import {
  convertArrayValuesToStrings,
  queryParamString,
  setCookie,
  updateUrlParams,
} from './utils'

export const ApiSearch = ({
  values,
  type,
  resultComponent,
  baseEndPoint,
  children,
}) => {
  const [results, setResults] = useState(null)
  const [endpoint, setEndpoint] = useState(baseEndPoint)

  const searchRef = useRef(null)

  useEffect(() => {
    if (values && Object.keys(values).length > 0) {
      const newParams = convertArrayValuesToStrings(values)
      updateUrlParams(newParams)
      setCookie(`${type}_search_params`, JSON.stringify(newParams), 1)
    }

    const qs = queryParamString(values)
    const newEndpoint = baseEndPoint.split('?')[0] + qs

    setEndpoint(newEndpoint)
  }, [values])

  const { total_results_count } = results || {}

  let totalCountText = ''
  if (total_results_count > 1) {
    if (values.q) {
      totalCountText = I18n.t(`search.result_count_query_plural`, {
        count: total_results_count,
        query: values.q,
      })
    } else {
      totalCountText = I18n.t(`search.result_count_plural`, {
        count: total_results_count,
      })
    }
  } else if (total_results_count == 1) {
    if (values.q) {
      totalCountText = I18n.t(`search.result_count_query_singular`, {
        query: values.q,
      })
      totalCountText = I18n.t(`search.result_count_singular`)
    }
  }

  return (
    <div ref={searchRef}>
      {!!total_results_count && (
        <span style={{ float: 'right' }}>{totalCountText}</span>
      )}
      {children}

      <ApiRequestWorker setResults={setResults} endpoint={endpoint}>
        {React.createElement(resultComponent, { results, childRef: searchRef })}
      </ApiRequestWorker>
    </div>
  )
}

export default ApiSearch
