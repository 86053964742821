import React from 'react'
import PropTypes from 'prop-types'
import { goToAnchor } from 'react-scrollable-anchor'
import { FadeLoader } from 'react-spinners'
import KumpassHitList from './KumpassHitList'

export const KumpassResultList = ({
  q,
  loading,
  hasHits,
  hits,
  noEntries,
  children,
}) => {
  const resultCount = Object.keys(hits).length
    ? Object.keys(hits)
        .map(letter => hits[letter].length)
        .reduce((a, b) => a + b, 0)
    : 0

  const handleLetterClick = e => {
    e.preventDefault()
    goToAnchor(e.target.innerText)
  }

  return (
    <div>
      <div id="index" className="row tc">
        <div className="col-md-12">
          <p className="small-text">
            {!noEntries && !loading && (
              <>
                <span>{resultCount} Einträge&nbsp;</span>
                {q !== '' && (
                  <>
                    mit <i>{q}</i>
                  </>
                )}
              </>
            )}
            {loading && <span>Einträge werden geladen...</span>}
          </p>
          <ul className="capital_list clearfix">
            {Object.keys(hits).map(letter => {
              return (
                <li key={letter}>
                  <a href={'#' + letter} onClick={handleLetterClick}>
                    {letter}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      {children}

      <div id="result_list">
        {noEntries && (
          <div className="no_results tc">
            Keine Ergebnisse für {q} gefunden!
          </div>
        )}
        {loading && !hasHits && (
          <FadeLoader
            css={'display: block; margin: 0 auto;'}
            sizeUnit={'px'}
            size={25}
            color={'#7ED321'}
            loading={true}
          />
        )}
        {hasHits &&
          Object.entries(hits).map(([key, value]) => (
            <KumpassHitList key={key} letter={key} hits={value} />
          ))}
      </div>
    </div>
  )
}

KumpassResultList.defaultProps = {
  hits: {},
  loading: false,
  noEntries: false,
  q: '',
}

KumpassResultList.propTypes = {
  children: PropTypes.any,
  hasHits: PropTypes.bool,
  hits: PropTypes.object,
  loading: PropTypes.bool,
  noEntries: PropTypes.bool,
  q: PropTypes.string,
}

export default KumpassResultList
