import React, { useState, useEffect } from 'react'

export const useApiResponse = (path, method = 'get', params = null) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [myParams, setMyParams] = useState(null)

  useEffect(() => {
    if(!path) return;

    let promise;
    setError(null)
    setLoading(true)
    
    if(method === 'post') {
      promise = fetch(path, {...params, method: 'POST', cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }})
    } else {
      let urlParams = params ? `?${new URLSearchParams(params).toString()}` : ''
      promise = fetch(`${path}${urlParams}`)
    }
    
    promise.then((res) => {
      setLoading(false)
      setResponse(res)
    })
    .catch((e) => {
      setLoading(false)
      setError(e)
    })
  }, [path, method, myParams])

  useEffect(() => {
    if(JSON.stringify(params) !== JSON.stringify(myParams)) {
      setMyParams(params)
    }
  }, [params])

  return [response, loading, error]
}