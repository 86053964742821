import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import { formatDate } from '../../shared'

const DateFilter2 = ({ values, updateValues }) => {
  const datepickerProps = {
    locale: {
      daysOfWeek: I18n.t('date.abbr_day_names'),
      format: 'DD.MM.YYYY',
      cancelLabel: I18n.t('cancel'),
      applyLabel: I18n.t('apply'),
      firstDay: 1,
    },
    endDate: values?.endDate || moment().format('DD.MM.YYYY'),
    startDate: values?.startDate || moment().format('DD.MM.YYYY'),
    showDropdowns: true,
  }

  const renderDate = () => {
    const startDate = values?.startDate
    const endDate = values?.endDate
    if (!startDate && !endDate) return ''
    return `${formatDate(startDate)} - ${formatDate(endDate)}`
  }

  const handleDateChange = (_event, picker) => {
    const startDate = picker.startDate
    const endDate = picker.endDate
    updateValues({ startDate, endDate })
  }

  const handleDateClear = () =>
    updateValues({ startDate: undefined, endDate: undefined })

  return (
    <DateRangePicker
      initialSettings={datepickerProps}
      onApply={handleDateChange}
      onCancel={handleDateClear}>
      <input
        readOnly
        name="daterange"
        className="form-control"
        value={renderDate()}
        placeholder={I18n.t('date_placeholder')}
        autoComplete="off"
      />
    </DateRangePicker>
  )
}

DateFilter2.propTypes = {
  values: PropTypes.object,
  updateValues: PropTypes.func,
}

export default DateFilter2
