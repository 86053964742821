import { useTranslation } from 'react-i18next'

const ResultsCount = ({ count, limit, page }) => {
  const [t] = useTranslation()
  const from = limit * page || 1
  const until = (page + 1) * limit
  return (
    <div className="career-number">
      {t('career_overview_page:views.result', {
        from: from,
        until: until > count ? count : until,
        count: count,
      })}
      &nbsp;
      <span style={{ color: 'grey', fontSize: '0.8rem' }}>
        Seite {page + 1} von {Math.ceil(count / limit)}
      </span>
    </div>
  )
}

export default ResultsCount
