import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiFetch } from '../shared'
import { getValuesFromCaches } from './career_utils'
import SearchForm from './overview_pages/SearchForm'
import {
  queryParamString,
  setCookie,
  updateUrlParams,
  convertArrayValuesToStrings,
  setQueryParam,
} from './utils'
import ApiFilter from './career_overview_page/ApiFilter'
import EditArea from './career_overview_page/EditArea'
import ResultList from './career_overview_page/ResultList'
import ApiRequestWorker from './ApiRequestWorker'

export const CareerOverviewPage = () => {
  const [t] = useTranslation()

  const [results, setResults] = useState(null)
  const [values, setValues] = useState(null)
  const [endpoint, setEndpoint] = useState('')

  const childRef = useRef(null)

  useEffect(() => {
    if (!values) {
      const newValues = getValuesFromCaches()
      delete newValues.archived
      setValues(newValues || {})
      return
    }

    const newParams = convertArrayValuesToStrings(values)
    updateUrlParams(newParams)

    setCookie(`career_search_params`, JSON.stringify(newParams), 1)
    const qs = queryParamString(values)

    setEndpoint('/api/v1/career/search.json' + qs)
  }, [values])

  const updateValue = (attribute, newValue) => {
    const newValues = { ...values }
    newValues[attribute] = newValue

    setQueryParam('page', null)
    setValues(newValues)
  }

  const updateQuery = value => updateValue('q', value)

  return (
    <div>
      <div className="career-overview">
        <div className="row">
          {(window.DREAMin.intranet_request ||
            scrivito.in_editable_view()) && <EditArea />}
          <div className="col-md-12 search-box">
            <div className="job-search-container">
              <div
                className="job-search-box"
                style={{
                  backgroundImage: `url(${window.DREAMin.career.image})`,
                }}>
                <div className="search-container">
                  <SearchForm
                    ref={childRef}
                    performSearch={updateQuery}
                    defaultQuery={!values ? '' : values.q}
                    placeholder={t('search_results_page:views.new_search')}>
                    <span className="fas fa-search job-search-icon" />
                  </SearchForm>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ApiFilter updateValue={updateValue} values={values} />
        <ApiRequestWorker setResults={setResults} endpoint={endpoint}>
          <ResultList results={results} childRef={childRef} />
        </ApiRequestWorker>
      </div>
    </div>
  )
}

export default CareerOverviewPage
