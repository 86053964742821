import React from 'react'

export const CustomStyles = {
  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
}

export const DropdownIndicator = ({ innerRef, innerProps }) => {
  return (
    <div className="react-select__indicator">
      <i ref={innerRef} className="fas fa-caret-down" {...innerProps} />
    </div>
  )
}
