import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ type, name, className, checked, onChange, label }) => {
  return (
    <div className="category_filter rowflex" onClick={onChange}>
      <div className={`${className} section-icon category_title`}></div>
      <input
        className="catinput-large"
        id={name}
        type={type}
        name={name}
        checked={checked}
        readOnly
      />
      <p>{label}</p>
    </div>
  )
}

Checkbox.defaultProps = {
  type: 'checkbox',
  checked: false,
}

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Checkbox
