import React, { useEffect, useState } from 'react'

export const LayoutPreview = ({ layout: defaultLayout }) => {
  const [layout, setLayout] = useState(defaultLayout)

  const layoutClasses = { 'Layout 1': 'linklist-standard', 'Layout 2': 'links-arrows-left', 'Layout 3': 'links-arrows-right' }

  const layoutDiv = document.querySelector(
    'div[data-scrivito-field-name="layout"]'
  )

  const addEvents = () => {
    layoutDiv.querySelectorAll('li').forEach((li) => {
      li.addEventListener('click', (e) => {
        setLayout(layoutClasses[e.currentTarget.innerText])
      })
    })
  }

  useEffect(() => {
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    var observer = new MutationObserver(addEvents);
    observer.observe(layoutDiv, {
      childList: true
    });
  }, [])


  return (
    <section className='layout-preview scrivito_content_group linklist-widget'>
      <h3>{I18n.t('linklist_widget.preview')}</h3>
      <ul className={layout}>
        <li>
          <a href='#'>Link 1</a>
        </li>
        <li>
          <a href='#'>Link 2</a>
        </li>
        <li>
          <a href='#'>Link 3</a>
        </li>
      </ul>
    </section>
  )
}

export default LayoutPreview
