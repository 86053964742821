import React, { useEffect, useState } from 'react'

const StopFinder = () => {
  const [stop, setStop] = useState('')
  const [results, setResults] = useState([])

  const onChange = e => setStop(e.currentTarget.value)

  const loadResults = () => {
    if (!stop || stop.length < 3) return

    fetch(
      `https://www.mvv-muenchen.de/?eID=stopFinder&query=${encodeURIComponent(
        stop
      )}&allowAny=true`
    ).then(response => {
      response.json().then(res => {
        setResults(res.results.length > 0 ? res.results.slice(0, 7) : [])
      })
    })
  }

  useEffect(() => {
    if (results.length === 1) {
      document.querySelector('input[name="destinationSelector"]').checked = true
    }
  }, [results])

  useEffect(() => {
    const timer = setTimeout(() => {
      loadResults()
    }, 200)
    return () => clearTimeout(timer)
  }, [stop])

  const setFieldValue = target => {
    if (!results.length || !getCheckedValue()) return
    let nameField = document.querySelector(
      `[data-scrivito-field-name='${target}_destination']`
    )
    updateAndFocus(nameField, getCheckedName())
    const valueField = document.querySelector(
      `[data-scrivito-field-name='${target}_destination_value']`
    )
    updateAndFocus(valueField, getCheckedValue())
  }

  const updateAndFocus = (field, value) => {
    field.innerText = value
    $(field).scrivito('save', value)
  }

  const getCheckedValue = () => {
    return document.querySelector('input[name="destinationSelector"]:checked')
      .value
  }
  const getCheckedName = () => {
    return document.querySelector('input[name="destinationSelector"]:checked')
      .dataset.name
  }

  return (
    <div>
      <div className='search-and-set'>
        <section className="search scrivito_content_group">
          <h3>{I18n.t('mvv_widget.details.start_end')}</h3>
          <p>{I18n.t('mvv_widget.details.start_end_description')}</p>
          <input
            name="q"
            defaultValue={stop}
            onChange={onChange}
          />
        </section>
        <section className="buttons scrivito_content_group">
          <h3>{I18n.t('mvv_widget.details.takeover_start_or_end')}</h3>
          <button onClick={() => setFieldValue('start')}>
            {I18n.t('mvv_widget.details.start')}
          </button>
          &nbsp;
          <button onClick={() => setFieldValue('end')}>{I18n.t('mvv_widget.details.end')}</button>
        </section>
      </div>
      <section>
        {!!results.length && (
          <>
            <h3>{results.length} {I18n.t('mvv_widget.details.results')}</h3>
            {results?.map((res, index) => {
              return (
                <React.Fragment key={index.toString()}>
                  <label>
                    <input
                      type="radio"
                      name="destinationSelector"
                      value={res.stateless}
                      data-name={res.name}
                    />
                    &nbsp;
                    {res.name}
                  </label>
                  <br />
                </React.Fragment>
              )
            })}
          </>
        )}
      </section >
    </div>
  )
}

export default StopFinder
