import React from 'react'
import EditableImg from './editable_img/EditableImg'

const NewsBox = ({ image, big_image, children }) => {
  const className1 = big_image
    ? 'col-md-6 col-sm-12 news-image'
    : 'col-md-4 col-sm-12 news-image-small'
  const className2 = big_image
    ? 'col-md-6 col-sm-12 news-content'
    : 'col-md-8 col-sm-12 news-content-area'

  return (
    <div className="row news-box">
      {image ? (
        <React.Fragment>
          <div className={className1}>
            <EditableImg image={image} />
          </div>
          <div className={className2}>{children}</div>
        </React.Fragment>
      ) : (
        <div className="col-md-12 news-content-area">{children}</div>
      )}
    </div>
  )
}

export default NewsBox
