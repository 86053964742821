import React from 'react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const callDelay = 500

const KumpassForm = ({ defaultQuery, onChange }) => {
  let callID = 0
  const [query, setQuery] = useState(defaultQuery)

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (query !== defaultQuery) setQuery(defaultQuery)
  }, [defaultQuery])

  const onTextChange = event => {
    if (!onChange) return
    const { value } = event.target
    clearTimeout(callID)
    if (value.length !== 1) {
      callID = setTimeout(() => onChange(value), callDelay)
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(callID)
    }
  })

  return (
    <div className="form-group has-feedback">
      <div className="input-group">
        <input
          type="text"
          name="q"
          defaultValue={defaultQuery}
          // fix translations
          placeholder={'Suchen'}
          className="form-control"
          onChange={onTextChange}
        />
        <span className="input-group-btn">
          <button className="btn btn-default btn-md" id="search_btn">
            <span
              alt="Suchen"
              className="glyphicon glyphicon-search"
              title="Suchen"></span>
          </button>
        </span>
      </div>
    </div>
  )
}

KumpassForm.propTypes = {
  defaultQuery: PropTypes.string,
  getResults: PropTypes.func,
}

export default KumpassForm
