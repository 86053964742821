import {
  translate
} from 'components/TranslationProvider'
import Pagination from 'components/search_page/Pagination'
import HitList from 'components/search_page/HitList'
import KumpassImage from 'images/kumpass_logo.jpg'


class SearchCluster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cluster_name: this.props.filter,
      clusters: this.props.clusters,
      selected_cluster: this.props.selected_cluster,
      query: this.props.query,
      offset: 0,
      count: 0,
      page: 0,
      limit: this.props.size,
      cluster_url: this.props.cluster_url

    }
    this.setOffset = this.setOffset.bind(this)
    this.renderLayoutforCluster = this.renderLayoutforCluster.bind(this)

  }

  setOffset(page) {
    this.setState({ offset: page * this.state.limit })
  }

  renderLayoutforCluster(offset_end, url_with_query, count) {
    const { cluster_name, offset, selected_cluster } = this.state
      return(
        <div>
          <div className='cluster-title'>{I18n.t(`search.${cluster_name}`)}</div>
          {cluster_name == 'kumpass' && 
            <img src={KumpassImage} className='img-responsive'/>
          }
          <div>
            <HitList cluster_name={this.state.cluster_name} hits={_.slice(selected_cluster.results, offset, offset_end)} query={this.state.query} />
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {this.state.cluster_url != undefined &&
                <a className='button-lg ghost-button sel-distinction-color-button' href={url_with_query}>{'Alle Ergebnisse'}</a>
              }
            </div>
          </div>
         </div>)
  }

  render() {
    const { cluster_name, offset, selected_cluster } = this.state
    let offset_end = offset + this.state.limit
    let url_with_query = this.state.cluster_url + '?&q=' + this.state.query
    let count = selected_cluster.results.length
    if (count != 0) {
      if (cluster_name == 'all') {
        return (<div className='row'>
          <div className='col-md-12'>
            <HitList show_legacy_categories={this.props.show_legacy_categories} cluster_name={this.state.cluster_name} hits={_.slice(selected_cluster.results, offset, offset_end)} query={this.state.query} />
            <Pagination limit={this.state.limit} count={count} page={this.state.page} forcePage={this.state.page} goToPage={this.props.goToPage} setOffset={this.setOffset} />
          </div>
        </div>)
      } else {

        return (

          <div className='search-cluster'>
            <div className='list-group-item'>
              <div className="panel panel-default">
                {this.renderLayoutforCluster(offset_end, url_with_query, count)}
              </div>
            </div>
          </div>)
      }
    } else {
      return (<div></div>)
    }
  }
}

export default translate(['SearchCluster'])(SearchCluster)
