import PropTypes from "prop-types";

const Checkbox = ({ type = "checkbox", name, checked, onChange, label }) => (
  /*
    props:
      key: string
      name: string
      label: string
      checked: boolean
    events:
      onChange: function
  */

  <div className="choice">
    <label className="control control-radio square">
      <input type={type} name={name} checked={checked} onChange={onChange} />
      <div className="control_indicator square" />
      <div className="label-name">{label}</div>
    </label>
  </div>
);

Checkbox.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
