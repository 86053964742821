import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { PulseLoader } from 'react-spinners'
import './IconSelector-styles.scss'

export const IconSelector = ({
                               widget_id,
                               defaultColor,
                               colors,
                               defaultIcon,
                               font_size,
                               icon_attribute = 'icon',
                               color_attribute = 'color',
                               font_size_attribute = 'font_size',
                             }) => {
  const [categories, setCategories] = useState([])
  const [icons, setIcons] = useState([])
  const [fontSize, setFontSize] = useState(font_size)
  const [color, setColor] = useState(defaultColor)
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [key, setKey] = useState(null)
  const [loading, setLoading] = useState(true)
  const timer = useRef(null)

  useEffect(() => {
    if (defaultIcon && defaultIcon !== 'undefined' && defaultIcon !== '') {
      const icon_array = defaultIcon.split(' fa-')
      const style = icon_array[0]
      const id = icon_array[1]
      setSelectedIcon({ id, styles: [renderIconStyle(style)] })
    }
    fetch('/api/v1/icon_widget/categories.json')
      .then(response => response.json())
      .then(data => setCategories(data))
  }, [])

  useEffect(() => {
    setKey(categories[0])
  }, [categories])

  useEffect(() => {
    if (!selectedIcon || selectedIcon.id === undefined) return
    updateElement(icon_attribute, getIconClass(selectedIcon))
  }, [selectedIcon])

  useEffect(() => {
    updateElement(color_attribute, color)
  }, [color])

  useEffect(() => {
    updateElement(font_size_attribute, fontSize)
  }, [fontSize])

  const updateElement = (field, value) => {
    const element = document.querySelector(
      'div[data-scrivito-field-name="' + field + '"][data-scrivito-private-field-widget-id="' + widget_id + '"]'
    )
    element.innerText = value
    $(element).scrivito('save', value)
  }

  useEffect(() => {
    if (!key) return

    setLoading(true)
    const url = `/api/v1/icon_widget/icons_by_category.json?category=${key}`
    fetch(url)
      .then(response => response.json())
      .then(data => setIcons(data))
  }, [key])

  useEffect(() => {
    setLoading(false)
  }, [icons])

  const onChange = eventKey => {
    setKey(eventKey.target.value)
  }
  const onTextChange = event => {
    const text = event.target.value
    if (text.length < 1) return

    clearInterval(timer.current)
    // delay call for 500ms
    timer.current = setTimeout(() => {
      setLoading(true)
      const url = `/api/v1/icon_widget/search.json?q=${text}`
      fetch(url)
        .then(response => response.json())
        .then(data => setIcons(data))
    }, 500)
  }

  const onRangeChange = event => {
    const value = event.target.value
    setFontSize(value)
  }


  return (
    <div className="icon-selector">
      <Row>
        <Col sm={12}>
          <div className="scrivito_content_group">
            <div>
              <Form.Control
                type="text"
                placeholder={I18n.t('icon_widget.details.search')}
                onChange={onTextChange}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
              <div className="category-filter">
                <Form.Select
                  size="lg"
                  onChange={onChange}
                  className="react-select-container">
                  {categories.map((category, index) => {
                    return (
                      <option key={category} value={category}>
                        {I18n.t(`icon_widget.details.${category}`)}
                      </option>
                    )
                  })}
                </Form.Select>
              </div>
              <div>
                <PulseLoader size={4} loading={loading}/>
                <ul
                  className="scrivito_enum_editor icon-selection"
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  {icons.map((icon, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          icon.id === selectedIcon?.id
                            ? 'scrivito_enum_active'
                            : ''
                        }
                        data-scrivito-toggle-value={icon.id}
                        onClick={() => setSelectedIcon(icon)}>
                        <IconView icon={icon}/>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <div className="scrivito_content_group second-row">
            <h3>{I18n.t('icon_widget.details.preview')}</h3>
            {selectedIcon ? (
              <div className='preview-box'>
                {selectedIcon && <IconView icon={selectedIcon}
                fontSize={fontSize}
                color={color} />}
              </div>
            ) : (
              <p className="small">
                {I18n.t('icon_widget.details.choose_to_view')}
              </p>
            )}
          </div>
        </Col>
        <Col sm={4}>
          <div className="scrivito_content_group second-row">
            <h3>{I18n.t('icon_widget.details.font_size')}</h3>
            <div className="font-size-selector">
              <Form.Control
                type="number"
                value={fontSize}
                min={50}
                max={1500}
                onChange={onRangeChange}
              />
              <Form.Range
                min={50}
                max={1000}
                onChange={onRangeChange}
                value={fontSize}
              />
            </div>
            <p className="small">
              {I18n.t('icon_widget.details.font_size_desc')}
              &nbsp;
              <IconView icon={selectedIcon} fontSize='1rem' />
            </p>
          </div>
        </Col>
        <Col sm={5}>
          <div className="scrivito_content_group second-row">
            <h3>{I18n.t('icon_widget.details.color')}</h3>
            {colors.map((col, index) => {
              return (
                <span
                  key={index}
                  title={col}
                  className={`color-selector ${col} ${
                    color === col ? 'active' : ''
                  }`}
                  onClick={() => setColor(col)}
                  >
                  {selectedIcon ? (
                    <IconView icon={selectedIcon}/>
                  ) : (
                    <IconView icon={{id: 'circle', styles: ['solid']}}/>
                  )}
                </span>
              )
            })}
          </div>
        </Col>
      </Row>
    </div>
  )

}

IconSelector.defaultProps = {
  font_size: 100,
}

const IconView = ({ icon, color='', fontSize }) => {
  if (!icon) return null
  if (!fontSize) {
    fontSize = '2rem'}
  else if(!Number.isNaN(Number(fontSize))) {
      fontSize = fontSize + '%'
    }
  let classes = getIconClass(icon)
  if(color !== '') classes += ` ${color}`
  return <i className={classes} title={icon.label} style={{fontSize: fontSize}} />
}

const getIconClass = icon => {
  if (!icon) return 'fas fa-question'
  const iconStyleCss = renderIconStyleClass(icon.styles)
  return `${iconStyleCss} fa-${icon.id}`
}

const renderIconStyleClass = iconStyle => {
  if (iconStyle.includes('solid')){
    return 'fas';
  } else if (iconStyle.includes('regular')){
    return 'far';
  } else if (iconStyle.includes('brands')){
    return 'fab';
  }
  return '';
}

const renderIconStyle = iconStyleClass => {
  if (iconStyleClass === 'fas'){
    return 'solid';
  } else if (iconStyleClass === 'far'){
    return 'regular';
  } else if (iconStyleClass === 'fab'){
    return 'brands';
  }
  return '';
}

export default IconSelector
