import PropTypes from 'prop-types'
import React from 'react'
import Highlighter from 'react-highlight-words'

const Highlight = ({ text, query }) => {
  if (!text) return null
  if (!query) return <span>{text}</span>

  return (
    <Highlighter
      highlightClassName="highlight"
      searchWords={query.split(' ')}
      autoEscape={true}
      textToHighlight={text}
    />
  )
}

Highlight.defaultProps = {
  query: '',
}

Highlight.propTypes = {
  query: PropTypes.string,
  text: PropTypes.string,
}

export default Highlight
