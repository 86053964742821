import Hit from 'components/search_page/Hit'

export default ({ hits, query, cluster_name, show_legacy_categories}) => {
  return (
    <div>
    {
      hits.map((hit, index) => {
        return <Hit
          title={hit.title}
          body={hit.body}
          link={hit.url}
          query={query}
          key={hit.id}
          class={hit.class}
          cluster_name={cluster_name}
          date_category={hit.date_category}
          location={hit.location}
          short_date_day={hit.short_date_day}
          short_date_month= {hit.short_date_month}
          show_legacy_categories={show_legacy_categories}
        />
      })
    }
    </div>
  )
}
