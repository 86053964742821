import { FILTER_ICONS } from '../../shared'

export const StatusIcons = ({ hit }) => {
  const { errors, publish_ba, internal_status, archived } = hit
  const { logged_in, intranet_request } = window.DREAMin

  const icons = {}
  if (scrivito.in_editable_view()) {
    if (publish_ba === 'yes') icons['publish_ba'] = 'alert-info'
    if (errors) icons['errors'] = 'alert-danger'
  }
  if ((logged_in || intranet_request) && internal_status === 'intranet') {
    icons['intranet_only'] = 'alert-info'
  }
  if (archived === 'yes') icons['archived'] = 'alert-info'

  return (
    <React.Fragment>
      {Object.entries(icons).map(([key, _value]) => {
        const tr = I18n.t(`career_overview_page.filter_names.${key}`)
        return (
          <span key={key} style={{ fontSize: '77%' }} alt={tr} title={tr}>
            &nbsp;
            <i className={FILTER_ICONS[key]}></i>
          </span>
        )
      })}
    </React.Fragment>
  )
}
