import ReactPaginate from 'react-paginate'
import { translate } from 'components/TranslationProvider'

class Pagination extends React.Component {
  pageClickHandler = data => {
    const selected = data.selected
    this.props.setOffset(selected)
  }

  render() {
    const { forcePage } = this.props
    const pageCount = Math.ceil(this.props.count / this.props.limit)
    const t = this.props.t

    if (pageCount <= 1) return null

    return (
      <div>
        <ReactPaginate
          previousLabel={<i className="fas fa-chevron-left" />}
          nextLabel={
            <div className="previous">
              <i className="fas fa-chevron-right" />
            </div>
          }
          containerClassName="row pagination-area"
          breakLabel={
            <div className="pagination-number">
              {t('search_results_page:views.pagination_of')}&nbsp;{pageCount}
            </div>
          }
          forcePage={forcePage}
          pageCount={pageCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={0}
          onPageChange={this.pageClickHandler}
          activeClassName="pagination-activ"
        />
      </div>
    )
  }
}
export default translate(['Pagination'])(Pagination)
