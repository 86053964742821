import Highlight from 'components/search_page/Highlight'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { eventDateString } from '../../shared'
import ArchiveDetail from '../ArchiveDetail'
import ParamContext from '../overview_pages/ParamContext'

const Event = ({ item }) => {
  const { archived, q: query } = useContext(ParamContext)
  const { date_box, key, title, homepage_title, course_repetition, location, portal, category, e_learning, search_snippet, lower_caption, flag } = item

  const getDetailLabel = () => {
    if (e_learning === true) {
      return I18n.t('event_page.details.e_learning')
    } else if (course_repetition === 'regularly') {
      return I18n.t('enum_lists.course_repetition.regularly')
    } else {
      return eventDateString(item)
    }
  }

  if (archived) {
    return <ArchiveDetail published_on={getDetailLabel()} url={key} title={title} />
  }

  return (
    <div className="search-hit">
      <div className="event-date-box vcenter col-md-2 col-xs-5">
        <div className="date-box ">{date_box}</div>
      </div>
      <div className="event-detail-box col-md-10 col-xs-10">
        <h2>
          <a href={key}>
            <Highlight text={title} query={query} />
          </a>
        </h2>
        {portal == true && (
          <p className="hit-abstract">
            <span className="label label-info ">{homepage_title}</span>
            {location}
          </p>
        )}
        <div className="event-infos">
          <div className="event-detail">{getDetailLabel()}</div>
          {category && !!category.length && (
            <div className="event-detail">
              <div className="event-category">
                <div className="category-element">{category.join(', ')}</div>
              </div>
            </div>
          )}
        </div>
        {(search_snippet || lower_caption) && (
          <div className="event-search-snippet">
            <div className="hit-abstract">{search_snippet ? <Highlight text={search_snippet} query={query} /> : <p>{lower_caption}</p>}</div>
          </div>
        )}
        {flag && (
          <div className="event-flags">
            <span className={`event-flag ${flag}`}>{I18n.t('enum_lists.flag.' + flag)}</span>
          </div>
        )}
      </div>
    </div>
  )
}

Event.propTypes = {
  archived: PropTypes.bool,
  eventObj: PropTypes.object,
  query: PropTypes.string,
}

export default Event
