import { useState } from 'react'
import { PulseLoader } from 'react-spinners'
import { translate } from 'components/TranslationProvider'
import { fetchPost, validEmail } from '../../shared'

const COLORS_BY_STATUS = {
  200: 'success',
  404: 'warning',
  400: 'danger',
  500: 'danger',
}
const WORDING_BY_STATUS = {
  200: 'should_receive_mail',
  404: 'not_found',
  400: 'bad_request',
  500: 'something_went_wrong',
}

const CareerPasswordRequest = ({ t }) => {
  const [active, setActive] = useState(false)
  const [sending, setSending] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState('')
  const [response, setResponse] = useState(null)

  const submitForm = () => {
    if (!validEmail(selectedEmail)) {
      setResponse(null)
      return
    }
    setSending(true)
    fetchPost('/api/v1/career/password', { email: selectedEmail })
      .then(onResponse)
      .catch(onResponse)
  }

  const onResponse = e => {
    setSending(false)
    setResponse(e)
  }

  const handleSubmit = e => {
    submitForm()
    e.preventDefault()
  }

  const handleInput = e => {
    setResponse(null)
    setSelectedEmail(e.target.value.trim())
  }

  if (!active) {
    return (
      <div className="col-md-12">
        <p>
          <button className="button-auto ghost-button sel-distinction-color-button" onClick={() => setActive(true)}>
            {t('career_overview_page:views.retrieve_career_password')}
          </button>
        </p>
      </div>
    )
  }

  return (
    <div className="col-md-12">
      <div className="row send-pw-area">
        <hr />
        <form action="#" acceptCharset="UTF-8" onSubmit={handleSubmit}>
          <div className="col-md-6">
            <p>{t('career_overview_page:views.choose_mail_info')}</p>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="q"
              placeholder={t('career_overview_page:views.choose_your_mail')}
              className="form-control"
              value={selectedEmail}
              onChange={handleInput}
            />
          </div>
          <div className="col-md-2">
            {sending ? (
              <div className="text-center">
                <PulseLoader size={12} color="#666666" />
              </div>
            ) : (
              <input
                onClick={submitForm}
                className="btn btn-default btn-md"
                id="search_btn"
                type="button"
                disabled={!validEmail(selectedEmail) || response?.status == 200}
                value={t('career_overview_page:views.retrieve_password')}
              />
            )}
          </div>
        </form>
      </div>
      {response && (
        <div className="row">
          <div
            className={`alert alert-${
              COLORS_BY_STATUS[response.status] || 'info'
            }`}>
            {t(
              `career_overview_page:views.${
                WORDING_BY_STATUS[response.status] || 'should_receive_mail'
              }`
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default translate(['CareerPasswordRequest'])(CareerPasswordRequest)
