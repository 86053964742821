import { SEARCH_DROPDOWNS } from "./career_utils"

export const updateUrlParams = railsParams => {
  const trueElements = new URLSearchParams()
  
  Object.entries(railsParams).forEach(([key, value]) => {
    if (value) trueElements.append(key, value)
  })
  const np = `${window.location.pathname}?${trueElements}`
  replaceState(np)
}

export const parameterize = filter_params => {
  return [filter_params].map(o => new URLSearchParams(o).toString()).join('&')
}

const replaceState = (newPath) => {
  if (window.scrivito && window.scrivito.in_editable_view()) {
    window.parent.history.replaceState(null, '', newPath)
  } else {
    history.replaceState(null, '', newPath)
  }
}

/**
 * Convert array values to comma seperated 
 * @param {object} params 
 * @returns object with array values joined by comma. 
 */
export const convertArrayValuesToStrings = (params) => {
  if(!params) return {}
  const converted = {}
  Object.entries(params).forEach(([key, val]) => {
    if(typeof val != 'undefined' && val != '') {
      if(typeof val === 'string' && val.toString() == 'false') val = 'no'
      if(Array.isArray(val)) {
        converted[key] = val.map((option) => option.value).join(",")
      } else if(typeof val === 'object') {
        converted[key] = JSON.stringify(val)
      }else {
        converted[key] = val
      }
    }
  })
  return converted
}

/**
 * Return query string value of name
 * @param {string} name 
 * @returns value
 */
export const getQueryParam = (name) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(name)
}

/**
 * Set query string of name with value
 * @param {string} name 
 * @param {string} value 
 */
export const setQueryParam = (name, value) => {
  const params = new URLSearchParams(window.location.search)
  if(value == null) {
    params.delete(name)
  } else {
    params.set(name, value)
  }
  const np = `${window.location.pathname}?${params.toString()}`
  replaceState(np)
}

/**
 * @param {object} params 
 * @returns string with ? to append to path
 */
export const queryParamString  = (params) => {
  if(!params) return ''
  if(Object.keys(params).length === 0) return ''
  return `?${new URLSearchParams(convertArrayValuesToStrings(params)).toString()}`
}

/**
 * set a cookie
 * @param {string} name
 * @param {string} value
 * @param {number} days
 */
 export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

/**
 * get a cookie value
 * @param {string} name
 * @returns value or null
 */
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

/**
 * @param {params} oldValues 
 * @returns converts comma seperated string values into their respective values
 * from DREAMin js config in window object 
 */
export const parseStashedValues = (oldValues) => {
  SEARCH_DROPDOWNS.forEach(element => {
    if (oldValues[element]) {
      const entries = oldValues[element].split(',')
      const options = getCareerFilterOptions(element)
      oldValues[element] = []
      entries.forEach(entry => {
        oldValues[element].push(
          options.find(option => option.value === entry)
        )
      })
    }
  })
  return oldValues
}

export const getCareerFilterOptions = (element) => {
  return window.DREAMin.career[`${element}_filter_options`]
}