import React from 'react'
import PropTypes from 'prop-types'

function NewDateFilter({
                         searchFilters, updateSearchFilters, startDateRef, endDateRef,
                       }) {
  // enables showing date range on pre-filtered pages
  if (searchFilters?.startDate) {
    startDateRef.current.value = searchFilters?.startDate
  }
  if (searchFilters?.endDate) {
    endDateRef.current.value = searchFilters?.endDate
  }

  // The HTML date input's output format is 'YYYY-MM-DD'
  const handleStartDateChange = (e) => {
    const startDate = e.target.value
    if (!searchFilters?.endDate || startDate <= searchFilters?.endDate) {
      updateSearchFilters({ startDate })
    } else {
      e.target.setCustomValidity(I18n.t('events_overview_page.index.start_date_too_late'))
      e.target.reportValidity()
    }
  }

  const handleEndDateChange = (e) => {
    const endDate = e.target.value
    if (!searchFilters?.startDate || endDate >= searchFilters?.startDate || endDate === '') {
      updateSearchFilters({ endDate })
    } else {
      e.target.setCustomValidity(I18n.t('events_overview_page.index.end_date_too_soon'))
      e.target.reportValidity()
    }
  }

  return (
    <fieldset className='timespan'>
      {/* <legend>{I18n.t('events_overview_page.index.date_filter')}</legend> */}
      <label htmlFor='timespan_start'>
        {I18n.t('events_overview_page.index.start_date')}
        <input type='date' id='timespan_start' ref={startDateRef} onChange={handleStartDateChange} />
      </label>
      <label htmlFor='timespan_end'>
        {I18n.t('events_overview_page.index.end_date')}
        <input type='date' id='timespan_end' ref={endDateRef} onChange={handleEndDateChange} />
      </label>
    </fieldset>
  )
}

NewDateFilter.propTypes = {
  searchFilters: PropTypes.object.isRequired,
  updateSearchFilters: PropTypes.func.isRequired,
  startDateRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  endDateRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
}

export default NewDateFilter
