import React, { useEffect, useRef, useState } from 'react'
import { getUrlParams } from '../shared'
import ApiSearch from './ApiSearch'
import EventList from './events_overview_page/EventList'
import ArchiveToggler from './overview_pages/ArchivedToggler'
import ParamContext from './overview_pages/ParamContext'
import { SearchFilters } from './overview_pages/SearchFilters'
import SearchForm from './overview_pages/SearchForm'
import { deleteCookie, updateUrlParams } from './utils'
import NewDateFilter from './news_filter_component/filters/NewDateFilter'

export const EventsOverviewPage = ({
  search_path,
  type,
  filters,
  dateFilter,
}) => {
  const [values, setValues] = useState({})
  const [filterObjects, setFilterObjects] = useState([])
  const [archived, setArchived] = useState(false)
  const [q, setQ] = useState('')

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  useEffect(() => {
    if (filters) {
      const fos = Object.entries(filters).map(([key, value]) => {
        return {
          name: key,
          options: value,
          selectedOptions: [],
          multiple: true,
        }
      })
      setFilterObjects(fos)
    }

    const urlParams = getUrlParams()
    let params = Object.keys(urlParams).length > 0 ? urlParams : {}

    if (params.archived) setArchived(urlParams.archived)
    if (params.q) setQ(urlParams.q)

    setValues(params)
  }, [])

  const updateValue = (attribute, newValue) => {
    const newValues = { ...values }
    newValues[attribute] = newValue

    if (attribute === 'archived') setArchived(newValue)
    if (attribute === 'q') setQ(newValue)

    setValues(newValues)
  }

  const updateValues = vals => {
    const newValues = { ...values, ...vals }

    setValues(newValues)
  }

  const updateQuery = value => updateValue('q', value)

  const searchForm = () => {
    return (
      <SearchForm
        performSearch={updateQuery}
        defaultQuery={q}
        placeholder={I18n.t('events_overview_page.index.search_placeholder')}
      />
    )
  }

  const filterViews = () => {
    return (
      <React.Fragment>
        {dateFilter && (
          <div className="col-md-6 date">
            <NewDateFilter
              searchFilters={values}
              updateSearchFilters={updateValues}
              startDateRef={startDateRef}
              endDateRef={endDateRef}/>
          </div>
        )}

        <SearchFilters onChangehandler={updateValue} />
      </React.Fragment>
    )
  }

  const resetFilters = () => {
    setArchived(false)
    setQ('')
    deleteCookie(`${type}_search_params`)
    updateUrlParams({})
    setValues({})
    startDateRef.current.value = '';
    endDateRef.current.value = '';
  }

  const updateArchived = value => {
    updateValue('archived', value)
  }

  return (
    <ParamContext.Provider
      value={{
        archived,
        setArchived: updateArchived,
        q,
        setQ,
        filterObjects,
        values,
      }}>
      <div className="filter-area row">
        <ArchiveToggler type={type} />
      </div>

        {Object.keys(filters).length > 2 ? (
          <>
            <div className="filter-area row">
              <div className="row" style={{ marginBottom: '16px' }}>
                <div className="col-md-6">{searchForm()}</div>
              </div>
            {filterViews()}
            </div>
          </>
        ) : (
          <>
            <div className="filter-area row">
              <div className="col-md-12 event-overview-page search">{searchForm()}</div>
            </div>
            <div className="filter-area row filters">
              {filterViews()}
            </div>
          </>
        )}

        {Object.keys(values || {}).length > 0 && (
          <div className="col-md-9">
            <a className="filter-delete" onClick={resetFilters}>
              {I18n.t('events_overview_page.index.clear_filter')}
            </a>
          </div>
        )}


      <ApiSearch
        baseEndPoint={search_path}
        values={values}
        type="event"
        resultComponent={EventList}></ApiSearch>
    </ParamContext.Provider>
  )
}

EventsOverviewPage.defaultProps = {
  type: 'page',
}

export default EventsOverviewPage
