import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { scrollToTop } from '../../shared'
import { deleteCookie, getQueryParam, setQueryParam } from '../utils'
import Hit from './Hit'
import Pagination from './Pagination'
import ResultsCount from './ResultsCount'

export const ResultList = ({ results, childRef }) => {
  const LIMIT = 10
  const [t] = useTranslation()
  const [page, setPage] = useState(0)

  const goToPage = newPage => {
    if (childRef) scrollToTop(childRef)
    setPage(newPage)
    setQueryParam('page', newPage)
  }

  useEffect(() => {
    if (results) {
      setPage(0)
      setQueryParam('page', null)
    }
  }, [results])

  useEffect(() => {
    const qp = getQueryParam('page')
    setPage(qp || 0)
  }, [])

  const clearFilters = () => {
    setPage(0)
    deleteCookie('career_search_params')
    window.location.search = ''
  }

  const clearFilterButton = (
    <button
      onClick={clearFilters}
      className="button button-lg ghost-button sel-distinction-color-button-invers">
      {t('career_overview_page:views.clear_filters')}
    </button>
  )

  if (!results || !results.length) {
    let noResultHeadline = 'career_overview_page:views.no_results_headline'
    let noResult = 'career_overview_page:views.no_results'
    if (scrivito.in_editable_view()) {
      noResultHeadline += '_editor'
      noResult += '_editor'
    }
    return (
      <div className="row no-result-box">
        <div className="no-result-note col-md-8 col-md-offset-2 col-sm-12">
          <div className="result-head">{t(noResultHeadline)}</div>
          <div className="result-body">{t(noResult)}</div>
          <div>
            {clearFilterButton}
            &nbsp;
            {!scrivito.in_editable_view() && (
              <a
                className="button button-lg ghost-button sel-distinction-color-button"
                name="bewerbung"
                type="submit"
                value="abonnieren"
                href={window.DREAMin.career.initiative_application_path}>
                {t('career_overview_page:views.application_now')}
              </a>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <div className="rowflex justified filter-area results-head">
          <ResultsCount count={results.length} limit={LIMIT} page={page} />
          <Pagination
            count={results.length}
            limit={LIMIT}
            forcePage={page}
            goToPage={goToPage}
          />
        </div>
        <div>
          {results
            .slice(page * LIMIT, page * LIMIT + LIMIT)
            .map((hit, index) => (
              <div className="row results" key={index}>
                <Hit hit={hit} />
              </div>
            ))}
        </div>
        <div>
          <Pagination
            count={results.length}
            limit={LIMIT}
            forcePage={page}
            goToPage={goToPage}
          />
        </div>
      </React.Fragment>
    )
  }
}

ResultList.defaultProps = {
  results: [],
}

export default ResultList
