import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReviewStatusIcon from '../news_overview_page/ReviewStatusIcon'
import { fetchPost } from '../../shared'
import ErrorLayer from '../ErrorLayer'

export const ReviewStatusSwitcher = ({ id, review_status }) => {
  const [reviewStatus, setReviewStatus] = useState(review_status)
  const [error, setError] = useState(null)

  const onAcceptClick = () => {
    setReviewStatus('accepted')
    updateStatus('accepted')
  }
  const onDenyClick = () => {
    setReviewStatus('rejected')
    updateStatus('rejected')
  }

  const updateStatus = value => {
    setError(null)
    fetchPost('/api/v1/admin/news_page/update.json', {
      id,
      review_status: value,
    })
      .then(e => {
        e.json()
          .then(res => {
            if (res.status === 'error') setError(res)
          })
          .catch(setError)
      })
      .catch(setError)
  }

  return (
    <div>
      <ErrorLayer error={error} />
      <div className="review-status-switcher">
        <ReviewStatusIcon reviewStatus={reviewStatus} />
        {reviewStatus !== 'accepted' && (
          <button onClick={onAcceptClick}>
            {I18n.translate('news_page.details.accept')}
          </button>
        )}
        {reviewStatus !== 'rejected' && (
          <button onClick={onDenyClick}>
            {I18n.translate(
              'news_page.details.' +
                (reviewStatus === 'waiting' ? 'deny' : 'revoke')
            )}
          </button>
        )}
      </div>
    </div>
  )
}

ReviewStatusSwitcher.propTypes = {
  id: PropTypes.string,
  review_status: PropTypes.string.isRequired,
}

export default ReviewStatusSwitcher
