// React component for EditableImage inside React.
const EditableImg = ({ image }) => {
  return(
    <div className='crop' style= {{maxWidth: `${image.selected_scaled[2]}px`}} >
      <img src={image.url} alt={image.alt} title={image.title} style= {{width: `${image.image_width}%`, marginTop: `-${image.top_margin}%`, marginLeft: `-${image.left_margin}%`, marginBottom: `-${image.bottom_margin}%`}} />
    </div>
    );
}

export default EditableImg;
