import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

const SearchForm = React.forwardRef(
  (
    { defaultQuery, performSearch, placeholder, children, onSubmit },
    jumpRef
  ) => {
    const callID = useRef(1)
    const [query, setQuery] = useState(defaultQuery)

    useEffect(() => {
      if (query !== defaultQuery) setQuery(defaultQuery)
    }, [defaultQuery])

    useEffect(() => {
      if (!defaultQuery && window.location.search.startsWith('?q='))
        setQuery(window.location.search.replace('?q=', ''))
    }, [])

    const onTextChange = event => {
      const { value } = event.target
      clearTimeout(callID.current)
      if (value.length > 1) {
        callID.current = setTimeout(() => performSearch(value), 500)
      } else {
        performSearch('')
      }
    }

    useEffect(() => {
      return () => {
        clearTimeout(callID.current)
      }
    })

    const handleSubmit = event => {
      if (onSubmit) {
        onSubmit(event)
      } else {
        event.preventDefault()
        const { value } = event.currentTarget.getElementsByTagName('input')[0]
        performSearch(value)
      }
    }

    return (
      <form action="" acceptCharset="UTF-8" onSubmit={handleSubmit}>
        <div className="has-feedback">
          {jumpRef && <div ref={jumpRef} className="job-search-ref"></div>}
          <input
            type="text"
            name="q"
            defaultValue={query}
            placeholder={placeholder}
            className="form-control"
            onChange={onTextChange}
          />
          {children || (
            <span className="glyphicon glyphicon-search form-control-feedback" />
          )}
        </div>
      </form>
    )
  }
)

SearchForm.defaultProps = {
  children: false,
}
SearchForm.propTypes = {
  children: PropTypes.any,
  defaultQuery: PropTypes.string,
  performSearch: PropTypes.func,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default SearchForm
